import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from '../elements/Modal';
import './CustomSignIn.css';


const CustomSignIn = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const [error, setError] = useState(null);
    const [isLogin, setIsLogin] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showConfirmSignup, setShowConfirmSignup] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const handleModalClose = (buttonClicked) => {
        setShowModal(false);
    };
    
    const handleSignup = async () => {
        // setShowModal(true);
        // return;
        if (email === '') {
            setError("Please enter your email");
            return;
        } else if (password === '') {
            setError("Please enter a password");
            return;
        }
        else if (password !== confirmPassword) {
            setError("Passwords don't match");
            return;
        } else if (isAgreed !== true) {
            setError("Please agree to Terms of Service and Privacy Policy");
            return;
        }

        try {
            setError(null);
            await Auth.signUp({
                username: email,
                password: password

            });
            setShowConfirmSignup(true);
            alert('A verification code has been sent to your email address.');
        } catch (err) {
            setError(err.message);
        }
    };

    const handleResend = async () => {
        try {
            setError(null);
            await Auth.resendSignUp(email);
            setShowConfirmSignup(true);
        } catch (err) {
            alert('Error resending code. Please contact us for assistance.');
            setError(err.message);
        }
    };
    const handleConfirmation = async () => {
        try {
            setError(null);
            await Auth.confirmSignUp(email, code);
            setShowConfirmSignup(false);
            //   setShowLogin(true);
            await Auth.signIn(email, password);
            alert('Account successfully verified!');
            //location.state.state is legacy used for COnversation and creat convo and possible others?
            //prevState should be referenced downstream going forward
            navigate(location.state.pathname || '/', { state:  { ...location.state.state, prevState: location }})
        } catch (err) {
            alert('Incorrect Confirmation');
            setError(err.message);
        }
    };

    const handleLogin = async () => {
        try {
            setError(null);
            await Auth.signIn(email, password);
            navigate(location.state.pathname || '/', { state: { ...location.state.state, prevState: location } });
        } catch (err) {
            setError(err.message);
        }
    };

    const handleForgotPassword = async () => {
        try {
            setError(null);
            await Auth.forgotPassword(email);
            setIsLogin(true);
            setForgotPassword(true);
            alert('A verification code has been sent to reset your password.');
        } catch (err) {
            setError(err.message);
        }
    };
    const handleForgotPasswordSubmit = async () => {
        try {
            setError(null);
            await Auth.forgotPasswordSubmit(email, code, newPassword);
            setIsLogin(true);
            await Auth.signIn(email, newPassword);
            alert('Password successfully changed!');
            navigate(location.state.pathname || '/', { state: { ...location.state.state, prevState: location } });
        } catch (err) {
            setError(err.message);
        }
    };
    return (
        <div className='formcontainer1'>
            <Modal
                isOpen={showModal}
                onClose={handleModalClose}
                message="We deleted all users and user_data created before 11/09/24. Please Sign Up for a new account. Sorry for any inconvenience."
                buttons={['Ok']}
            />
            {!forgotPassword && showConfirmSignup ? (
                <div >
                    <h1 className='header1'>Confirm Signup</h1>
                    <input className='signininput' type="text" placeholder="Verification Code" onChange={(e) => setCode(e.target.value)} />
                    <div className='button-container-default'>
                        <button className="buttonClass3" onClick={handleConfirmation}>Confirm Signup</button>
                        <button className="buttonClass3" onClick={handleResend}>Resend Code</button>
                    </div>
                </div>
            ) : (
                !forgotPassword && <div>

                    {isLogin ? (
                        <div className='formcontainer2'>
                            {/* <div className='formcontainer2'>{isLogin ? "Don't have an account?" : 'Already have an account?'}
                                <button className="buttonClass3 signup-login-button" onClick={() => setIsLogin(!isLogin)}>{isLogin ? 'Signup' : 'Login'}</button>
                            </div> */}
                            <h1 className='header1'>Login
                            </h1>
                            <span className='login-signup-text'> Or
                                <button className="buttonClass3 signup-login-button" onClick={() => setIsLogin(!isLogin)}>Signup
                                </button> "if you don't have an account"
                            </span>
                            <input className='signininput' type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                            <input className='signininput' type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <div className='button-container-default'>
                                <button className="buttonClass3" onClick={handleLogin}>Submit</button>
                                <button className="buttonClass3" onClick={handleForgotPassword}>Reset Password</button>
                            </div>
                        </div>
                    ) : (
                        !forgotPassword && <div className='formcontainer2'>
                            <h1 className='header1'>Signup</h1>
                            <span className='login-signup-text'> Or
                                <button className="buttonClass3 signup-login-button" onClick={() => setIsLogin(!isLogin)}>Login
                                </button>
                                if you already have an account
                            </span>


                            <input className='signininput' type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                            <input className='signininput' type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <input className='signininput' type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />

                            <div className="agreement-container">
                                <input
                                    type="checkbox"
                                    id="agreement"
                                    name="agreement"
                                    checked={isAgreed}
                                    onChange={() => setIsAgreed(!isAgreed)}
                                    className="agreement-checkbox"
                                />
                                <label htmlFor="agreement" className="agreement-label">
                                    I agree to the <a className="signin-links" href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a className="signin-links" href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                                </label>
                            </div>
                            <div className='button-container-default'>
                                <button className="buttonClass3" onClick={handleSignup}>Submit</button>
                            </div>
                        </div>
                    )}

                    {error && <p>{error}</p>}
                </div>
            )}
            {forgotPassword && (
                <div>
                    <h1>Reset Password</h1>
                    <input className='signininput' type="text" placeholder="Verification Code" onChange={(e) => setCode(e.target.value)} />
                    <input className='signininput' type="password" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} />
                    <div className='button-container-default'>
                        <button className="buttonClass3" onClick={handleForgotPasswordSubmit}>Submit</button>
                    </div>
                    {error && <p>{error}</p>}
                </div>
            )}

        </div>
    );
};


export default CustomSignIn;
