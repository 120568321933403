import { CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const domain = process.env.REACT_APP_DOMAIN || 'localhost:3000';

const StripePayment = ({credits, price, handleEdit}) => {

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `http://${domain}`, // need to figure out how send to courthouse but also update credits on time
            },
        });

        if (error) {
            alert(error?.message);
        } 
    };


    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            
            <button className="buttonClass2" type="submit" disabled={!stripe}>
                Complete Purchase (${(credits * price).toFixed(2)})
            </button>
            
        </form>
    );

};
export default StripePayment