import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, message, buttons }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={() => onClose(null)}>&times;</span>
        <p>{message}</p>
        <div className="modal-button-container">
          {buttons.map((button, index) => (
            <button className="modal-button" key={index} onClick={() => onClose(button)}>
              {button}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;