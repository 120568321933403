const staticTopicsList = [
    'Basics of Sustainable Living',
    'Fundamentals of Personal Finance',
    'Exploring World Religions',
    'DIY Home Improvement Projects',
    'Starting Your Own Business',
    'History\'s Unsolved Mysteries',
    'Basics of Astronomy',
    'Creative Writing Techniques',
    'Understanding Cryptocurrencies',
    'Healthy Cooking and Nutrition',
    'Basics of Gardening',
    'World Geography and Cultures',
    'Introduction to Philosophy',
    'Basics of First Aid',
    'Digital Art and Design',
    'Public Speaking and Communication',
    'Animal Care and Training',
    'Travel Hacking Tips',
    'Introduction to Coding',
    'Exploring Classical Music',
    'Learning to Play a Musical Instrument',
    'Fundamentals of Photography',
    'Social Media Marketing',
    'Understanding Human Rights',
    'Fitness and Exercise Routines',
    'Introduction to Psychology',
    'Exploring Different Art Periods',
    'Time Management Strategies',
    'Learning Sign Language',
    'Basic Car Maintenance',
    'Introduction to Poetry',
    'Local Wildlife Identification',
    'Basics of Investing',
    'Understanding the Stock Market',
    'Beginner\'s Guide to Brewing Coffee',
    'Film History and Analysis',
    'Basics of Magic Tricks and Illusion',
    'Exploring Quantum Physics',
    'Understanding Different Political Systems',
    'Basic Sewing and Clothing Repair',
    'Space Exploration and the Universe',
    'Learning Chess Strategies',
    'Introduction to 3D Printing',
    'Fundamentals of Robotics',
    'Ancient World Civilizations',
    'Introduction to Bird Watching',
    'Basics of Calligraphy',
    'Discovering New Hiking Trails',
    'Urban Farming Techniques',
    'Introduction to Virtual Reality',
    'Learning About Marine Life',
    'Fundamentals of Cloud Computing',
    'Exploring World Mythologies',
    'Basic Electronics and Circuitry',
    'Historical Fashion Trends',
    'Learning About Rare Books',
    'Introduction to Forensic Science',
    'Understanding Your Dreams',
    'Basics of Archery',
    'Famous Battles in History',
    'Exploring the World of Comics',
    'Fundamentals of Game Design',
    'Learning About Dinosaurs',
    'Introduction to Feng Shui',
    'Basics of Pottery',
    'Exploring the Solar System',
    'Understanding Body Language',
    'Basics of Beekeeping',
    'Introduction to Tarot and Divination',
    'Learning About Tea Cultures',
    'Fundamentals of Animation',
    'Exploring Silent Films',
    'Basics of Carpentry',
    'Learning About Gemstones',
    'Understanding Neuroplasticity',
    'Introduction to Ethical Hacking',
    'Basics of Meteorology',
    'Learning About Antiques',
    'Fundamentals of Acupuncture',
    'Exploring Ballet and Dance',
    'Understanding Quantum Computing',
    'Learning About Perfumery',
    'Introduction to Homeopathy',
    'Basics of Lock Picking',
    'Exploring Cryptology',
    'Understanding Virtual Currencies',
    'Learning About Wine Tasting',
    'Introduction to Pilates',
    'Exploring the Human Genome',
    'Understanding Alternative Energies',
    'Learning About Aromatherapy',
    'Basics of Kinesiology',
    'Exploring Dark Matter and Dark Energy',
    'Advanced Programming Concepts',
    'Deep Dive into Historical Events',
    'Quantum Mechanics Simplified',
    'Mastering Chess Strategies',
    'Self-publishing Your Own Book',
    'Advanced Personal Finance Management',
    'Exploring Minimalist Living',
    'Understanding Advanced Astronomy',
    'Writing and Directing Short Films',
    'Advanced Gardening and Permaculture',
    'DIY Advanced Home Repairs',
    'Exploring World Philosophies',
    'Advanced Digital Art Techniques',
    'Learning Advanced Musical Composition',
    'Mastering Different Culinary Techniques',
    'In-depth Study of World Religions',
    'Learning Advanced Yoga Poses',
    'Self-guided Walking Tours and Exploration',
    'Advanced Photography Techniques',
    'Building Your Own Computer from Scratch',
    'Learning Advanced Magic Tricks',
    'Advanced Study of Human Psychology',
    'Creating Your Own Mobile Applications',
    'DIY Furniture Building',
    'Advanced Fitness Training and Anatomy',
    'Mastering the Art of Debate',
    'Learning Advanced Survival Skills',
    'Conducting Your Own Scientific Research',
    'Advanced Travel Hacking',
    'Deep Dive into Classical Literature',
    'Learning Advanced Painting Techniques',
    'Advanced Renewable Energy Systems',
    'Mastering Time Management',
    'Learning Advanced Sign Language',
    'Restoring a Classic Car',
    'Writing Poetry and Literature',
    'Learning About Endangered Species',
    'Advanced Investing Strategies',
    'Deep Dive into Global Economics',
    'Mastering Gourmet Coffee Making',
    'Film Making and Editing Techniques',
    'Learning Sleight of Hand and Advanced Illusions',
    'Advanced Quantum Physics Concepts',
    'Understanding Complex Political Ideologies',
    'Creating Your Own Clothing Line',
    'Advanced Space Exploration Technologies',
    'Mastering Chess Endgames',
    'Designing Advanced 3D Models',
    'Building and Programming Robots',
    'Deep Dive into Ancient Civilizations',
    'Advanced Bird Identification and Ornithology',
    'Mastering Calligraphy Styles',
    'Exploring Remote Hiking Locations',
    'Creating Virtual Reality Content',
    'Marine Biology and Oceanography',
    'Advanced Cloud Computing Concepts',
    'Mythology and Ancient Belief Systems',
    'Building Electronic Gadgets',
    'Historical Clothing Construction',
    'Collecting and Preserving Rare Books',
    'Forensic Science and Crime Scene Investigation',
    'Interpreting Dreams and the Subconscious',
    'Mastering Archery and Marksmanship',
    'Strategic Analysis of Historical Battles',
    'Creating Comic Books and Graphic Novels',
    'Developing Video Games',
    'Paleontology and Fossil Studies',
    'Advanced Feng Shui for Life Balance',
    'Creating Pottery and Ceramic Art',
    'Advanced Studies of the Solar System',
    'Mastering Non-verbal Communication',
    'Advanced Strategies for Collectible Card Games',
    'Sustainable Beekeeping Practices',
    'Tarot Reading and Symbolism',
    'Tea Cultures and Advanced Brewing Techniques',
    'Animation and Storyboarding',
    'Studying and Reproducing Silent Films',
    'Advanced Carpentry and Woodworking',
    'Gemology and Jewelry Making',
    'Advanced Concepts in Neuroplasticity',
    'Cybersecurity and Ethical Hacking',
    'Predicting Weather Patterns',
    'Restoring and Valuing Antiques',
    'Advanced Acupuncture Techniques',
    'Choreography and Dance Performance',
    'Quantum Computing Applications',
    'Creating Artisanal Perfumes',
    'Advanced Homeopathic Remedies',
    'Lock Picking and Security Systems',
    'Cryptography and Codebreaking',
    'Advanced Wine Tasting and Sommelier Skills',
    'Pilates Instructor Training',
    'Genomics and Genetic Engineering',
    'Designing Alternative Energy Systems',
    'Aromatherapy and Natural Healing',
    'Advanced Kinesiology and Movement',
    'Theories of Dark Matter and Dark Energy',
    'Building Credit and Managing Debt',
    'Resume Writing and Interview Skills',
    'Basics of Cooking for One or Two',
    'Time Management and Productivity',
    'Mental Health and Stress Management',
    'Understanding Health Insurance',
    'Networking and Personal Branding',
    'Apartment Hunting and Renting',
    'Basics of Car Maintenance',
    'Personal Safety and Self-Defense',
    'Establishing a Personal Budget',
    'Understanding Workplace Rights',
    'Social Media Etiquette and Privacy',
    'Healthy Relationship Boundaries',
    'Basic Home Cleaning and Organization',
    'Navigating Public Transportation',
    'Shopping for Groceries on a Budget',
    'Basics of Tax Preparation',
    'Conflict Resolution and Communication',
    'Learning Basic Sewing for Repairs',
    'Cultural Awareness and Sensitivity',
    'Introduction to DIY Home Repairs',
    'Planning for Travel on a Budget',
    'Emergency Preparedness',
    'Understanding Student Loans',
    'Self-care and Wellness Routines',
    'Basics of Gardening and Plant Care',
    'Introduction to Coding',
    'Creating a Professional Online Presence',
    'Learning a New Language',
    'Exploring Art and Creativity',
    'Physical Fitness on a Budget',
    'Understanding Basic Contracts and Agreements',
    'Public Speaking and Presentation Skills',
    'Local Volunteering Opportunities',
    'Exploring Local History and Attractions',
    'Basic Photography Skills',
    'Cooking with Dietary Restrictions',
    'Basics of Graphic Design',
    'Exploring Music and Learning an Instrument',
    'Basic Bicycle Maintenance and Safety',
    'Introduction to Meditation and Mindfulness',
    'Understanding Credit Cards and Loans',
    'Exploring Career Opportunities',
    'Outdoor Recreation and Conservation',
    'Basics of Event Planning',
    'Introduction to Philosophy and Critical Thinking',
    'Learning about Local Politics and Voting',
    'Basics of Interior Design and Decor',
    'Introduction to Yoga',
    'Learning Basic Woodworking Skills',
    'Recycling and Waste Reduction',
    'Basics of Podcasting',
    'Exploring World Literature',
    'Pet Care and Responsibilities',
    'Learning About Different Cultures',
    'Basic Electrical and Plumbing Skills',
    'Basics of Blogging',
    'Introduction to Personal Fitness Training',
    'Understanding Car Insurance',
    'Basics of Video Editing',
    'Learning About Environmental Conservation',
    'Introduction to Basic Bookkeeping',
    'Socializing and Making Friends in New Places',
    'Exploring Indie Gaming',
    'Basics of Baking',
    'Introduction to Dance',
    'Learning Basic Graphic Novels Drawing',
    'Understanding Nutrition and Supplements',
    'Basics of Web Design',
    'Introduction to Astronomy',
    'Learning About Mental Health Advocacy',
    'Exploring Different Writing Styles',
    'Introduction to Entrepreneurship',
    'Basics of Makeup and Skin Care',
    'Learning About Film and Cinema',
    'Introduction to 3D Printing',
    'Learning About Animal Welfare',
    'Introduction to Animation',
    'Exploring Local Art Scenes',
    'Basics of Sound Engineering',
    'Learning About Human Rights Issues',
    'Introduction to Creative Writing',
    'Basics of Mixology',
    'Exploring Theatre and Drama',
    'Introduction to Virtual Reality',
    'Basics of Online Marketing',
    'Introduction to Fashion Design',
    'Exploring Local Music Scenes',
    'Basics of Social Entrepreneurship',
    'Learning About Global Issues',
    'Introduction to Game Development',
    'Basics of Public Relations',
    'Exploring Veganism and Vegetarianism',
    'Introduction to Artificial Intelligence',
    'Basics of Cybersecurity',
    'Learning About Community Service',
    'History of Civil Rights Movements',
    'Financial Literacy for Low-Income Families',
    'Celebrating Cultural Heritage and Identity',
    'Navigating Discrimination in the Workplace',
    'Advocacy and Social Justice Organizing',
    'Understanding Your Legal Rights',
    'Health Disparities and Access to Healthcare',
    'Language Preservation and Revitalization',
    'Entrepreneurship in Underserved Communities',
    'Environmental Justice and Advocacy',
    'Body Positivity and Self-Esteem Building',
    'Creating Safe Spaces for LGBTQ+ Individuals',
    'Accessing Educational Opportunities',
    'Storytelling and Representation in Media',
    'Community Health and Wellness Initiatives',
    'Leadership Development in Youth',
    'Addressing Food Deserts and Nutrition',
    'Technology Access and Digital Literacy',
    'Understanding and Combating Systemic Racism',
    'Disability Rights and Accessibility',
    'Healing from Intergenerational Trauma',
    'Building and Supporting Inclusive Communities',
    'Homeownership and Real Estate Literacy',
    'Women\'s Rights and Gender Equity',
    'Immigration and Refugee Support Resources',
    'Indigenous Rights and Cultural Awareness',
    'Strategies for Nonviolent Protest',
    'Educational Equity and Advocacy',
    'Accessing Mental Health Support',
    'Cultivating Community Gardens and Sustainability',
    'Supporting Veteran Communities',
    'Prison System Reform and Advocacy',
    'Empowerment through Music and Performance',
    'Navigating Public Assistance Programs',
    'Climate Change Impacts on Marginalized Populations',
    'Voter Registration and Civic Engagement',
    'Cybersecurity and Privacy Protection',
    'Supporting Neurodiversity and Learning Differences',
    'Religious Tolerance and Interfaith Dialogues',
    'Anti-Bullying and Mental Health Support for Youth',
    'Career Development and Mentorship',
    'Affordable Housing and Tenant Rights',
    'Combating Gender-Based Violence',
    'Financial Support and Scholarships for Students',
    'Parenting Support and Family Resources',
    'LGBTQ+ Rights and History',
    'Addressing Substance Abuse and Recovery',
    'Cultural Competency in Healthcare',
    'Social Entrepreneurship for Community Development',
    'Addressing Homelessness and Providing Support',
    'First Aid and Emergency Response in Rural Areas',
    'Language Learning and Bilingualism',
    'Music and Cultural Preservation',
    'Sports for Community Building and Youth Development',
    'STEM Education in Low-Income Communities',
    'Understanding Climate Change and Environmental Policies',
    'Volunteerism and Community Service',
    'Wildlife Conservation in Indigenous Territories',
    'Yoga and Mindfulness for Trauma Recovery',
    'Zero-Waste Lifestyle and Community Clean-Up',
    'Historical Contributions of Marginalized Groups',
    'Nutrition Education and Cooking Skills',
    'Photography for Social Change',
    'Political Activism and Grassroots Campaigns',
    'Resilience and Stress Management Techniques',
    'Social Media for Social Good',
    'Traditional Medicine and Practices',
    'Urban Planning and Community Regeneration',
    'Violence Prevention and Conflict Resolution',
    'Writing and Publishing Diverse Stories',
    'Youth Empowerment and Leadership',
    'Building Credit and Wealth Management',
    'Community Art Projects and Collaboration',
    'Dance and Movement Therapy',
    'Educational Technology in the Classroom',
    'Family Planning and Reproductive Health',
    'Job Search Strategies and Networking',
    'Know Your Rights: Police Encounters',
    'LGBTQ+ Health and Wellness',
    'Mental Health First Aid',
    'Natural Disaster Preparedness and Response',
    'Overcoming Imposter Syndrome',
    'Queer Theory and Gender Studies',
    'Racial Equity in Education',
    'Sign Language and Inclusion',
    'Understanding Microaggressions and Bias',
    'Women in Leadership and Business',
    'Youth Mental Health and Suicide Prevention',

    'Understanding Positive Psychology in The Science of Happiness',
    'Conservation Efforts in Marine Ecosystems',
    'Exploring the World of Cryptocurrencies Beyond Bitcoin',
    'Prospects and Challenges of Artificial Intelligence in Healthcare',
    'From Communication to Marketing: The Evolution of Social Media',
    'The Art of Mixing Cuisines in Culinary Fusion',
    'Challenges and Opportunities in Space Colonization',
    'The Culture and History of Street Art',
    'Sustainability in the Fashion Industry through Ethical Fashion',
    'Innovations and Trends in Renewable Energy',
    'Exploring the Psychology of Leadership',
    'Growing Food in Small Spaces with Urban Gardening',
    'Exploring the Basics of Astrophysics',
    'Diverse Societies in the Modern World: Insights from Cultural Anthropology',
    'Techniques and Ethics in Wildlife Photography',
    'Materials and Applications in 3D Printing Innovations',
    'Team Dynamics and Fan Culture in The Sociology of Sports',
    'An Introduction to the World of Nanotechnology',
    'A Journey through the History of Animated Films',
    'Exploring the Fundamentals of Neuro-Linguistic Programming',
    'Nature\'s Light: Exploring the Phenomenon of Bioluminescence',
    'Cybernetic Enhancements and the Future of Humanity',
    'Psychedelics in Psychiatric Treatment: A New Frontier',
    'Quantum Computing and the End of Classical Encryption',
    'Post-Modernist Perspectives in Today\'s Digital Art',
    'The Ethics of AI: Machine Morality and Sentience',
    'Cultivating Utopia: Sustainable Communes in the Modern Age',
    'The Metaverse: Blurring Realities in Digital Existence',
    'Biohacking: The Synthesis of Biology and Technology',
    'Neuroaesthetics: Understanding Beauty through Brain Science',
    'Radical Architecture: Rethinking Space in the 21st Century',
    'Extremophiles and the Potential for Extraterrestrial Life',
    'Rewilding Urban Spaces: Harmony in Urban Ecology',
    'Holographic Technology: Changing the Face of Performance Art',
    'Cryptocurrencies and the Decentralization of Finance',
    'Sonic Art: Pushing the Boundaries of Sound and Music',
    'Speculative Fiction: Imagining Diverse Futures',
    'Transhumanism and the Quest for Technological Immortality',
    'Zero-Waste Lifestyles: Radical Sustainability',
    'The Art of Fermentation: Gastronomy and Microbiology',
    'Virtual Political Systems: Governance in the Digital Age',
    'CRISPR and Gene Editing: The New Frontier of Genomics',
    'Harnessing Fusion Energy: The Sun\'s Power on Earth',
    'The Microbiome and Human Health: Unseen Symbiosis',
    'Dark Matter and Dark Energy: Unraveling Cosmic Mysteries',
    'The Role of Quantum Entanglement in Computing and Communication',
    'Brain-Computer Interfaces: Merging the Neural with the Digital',
    'Nanorobotics in Medicine: The Future of Surgery',
    'The Ethics of Human Cloning: Possibilities and Controversies',
    'Artificial Wombs and the Future of Human Reproduction',
    'The Search for Exoplanets and Extraterrestrial Life',
    'Machine Learning in Drug Discovery: Accelerating Pharmaceuticals',
    'The Science of Aging: Reversing Time\'s Toll',
    'Synthetic Biology: Creating Life from Scratch',
    'Hyperloop Travel: The Future of Transportation',
    'Smart Cities: Urban Life Enhanced by Technology',
    'The Role of Artificial Intelligence in Creative Industries',
    'Emotion Recognition Technology: Applications and Implications',
    'The Environmental Impact of Cryptocurrency Mining',
    'Space Tourism: The Final Travel Frontier',
    'The Future of Food: Cellular Agriculture and Lab-Grown Meat',
    'The Ocean\'s Depths: Unknown Ecosystems Beneath the Waves',
    'Zero-Knowledge Proofs: Privacy in the Blockchain',
    'Rewriting History: The Use of DNA in Archaeology',
    'The Singularity: Preparing for AI Superintelligence',
    'Massive MIMO and the Future of Wireless Connectivity',
    'The Holographic Universe: A New Model of Cosmology',
    'Gravitational Waves: Listening to the Cosmos',
    'The Circular Economy: Beyond Recycling',
    'Molecular Computing: Storing Data in DNA',
    'The Connectome: Mapping the Neural Pathways of the Brain',
    'Green Chemistry: Sustainable Solutions in the Chemical Industry',
    'Immunotherapy: The Body\'s Own Weapon Against Cancer',
    'Autonomous Vehicles: The Road to Self-Driving Cars',
    'The Gamification of Education: Learning through Play',
    'The Fourth Industrial Revolution: Society Transformed by Tech',
    'Guerrilla Marketing: Unconventional Strategies',
    'Hacker Culture: Ethical Perspectives',
    'Urban Exploration: Unseen Metropolises',
    'Alternative Energies: Beyond Solar and Wind',
    'DIY Biohacking: Body Enhancements',
    'Anarchy: Historical and Modern Movements',
    'Cryptocurrency: Beyond Financial Systems',
    'Artificial Intelligence: Redefining Creativity',
    'Off-Grid Living: Self-Sustenance',
    'Virtual Reality: Defying Physical Limits',
    'Radical Urban Gardening: Green Revolutions',
    'Quantum Computing: New Logic Paradigms',
    'Minimalism: Lifestyle Extremism',
    'Dark Web: Internet\'s Hidden Facets',
    'Crowdfunding: Power to the People',
    'Nomadic Living: Modern Wanderers',
    'Zero Waste: Radical Sustainability',
    'Extreme Sports: Pushing Human Boundaries',
    'Indie Gaming: Defying Mainstream',
    'Underground Music Movements: Sonic Rebellion',
    'The Mystery of Déjà Vu: Glitch in the Matrix?',
    'Time Travel: Possibility or Fantasy?',
    'The Allure of Uninhabited Islands',
    'Lost Civilizations: What Secrets Remain?',
    'Famous Unsolved Codes and Ciphers',
    'The World\'s Most Mysterious Books',
    'Parallel Universes: Theories and Possibilities',
    'The Science of Dreams: What Do They Mean?',
    'Life-extending Technologies: The Quest for Immortality',
    'The Future of Deep-Sea Exploration',
    'Culinary Oddities from Around the World',
    'The Art of Mind Reading: Science or Magic?',
    'Human Evolution: What\'s Next?',
    'The Possibility of Alien Life',
    'The Psychology of Superstitions',
    'Reviving Extinct Species: Should We?',
    'The World\'s Lost Treasures: Still Undiscovered',
    'Historical Mysteries and Conspiracy Theories',
    'The Wildest Space Theories Scientists Actually Believe',
    'Artificial Intelligence: Friend or Foe?',
    'The Power of Music on Mood and Mind',
    'Unexplained Phenomena: Science and the Supernatural',
    'The World\'s Most Bizarre Natural Wonders',
    'The Limits of Human Perception',
    'The Most Puzzling Unexplained Disappearances',
    'Mythical Creatures: Folklore or Fact?',
    'The Hidden World of Microscopic Life',
    'Exploring the Concept of a Multiverse',
    'The Most Intriguing Spy Tactics in History',
    'The World\'s Greatest Unsolved Art Heists',
    'Bizarre Laws Still on the Books',
    'Incredible Coincidences in World Events',
    'Unbelievable Animal Friendships',
    'Hidden Messages in Famous Artworks',
    'Lost Worlds Under the Ocean',
    'Secrets of the World’s Longest-Living People',
    'Mysteries of the Bermuda Triangle',
    'Time Capsules: Messages to the Future',
    'Real Places Straight Out of Fairy Tales',
    'Historical Predictions That Came True',
    'Foods That Haven\'t Changed for Centuries',
    'Unsolved Mathematical Riddles',
    'Incredible Survival Stories',
    'Forgotten Heroes of History',
    'Treasures Found in Unexpected Places',
    'Most Haunted Places on Earth',
    'World Records No One Wants to Break',
    'Mind-Blowing Space Discoveries',
    'Ancient Inventions Ahead of Their Time',
    'Mysteries of the Human Brain',
    'Secret Societies and Their Rituals',
    'Animals with Surprising Abilities',
    'Historical Decisions That Changed the World',
    'Baffling Phenomena Scientists Can\'t Explain',
    'Richest People of All Time',
    'Greatest Magic Tricks Revealed',
    'Rare Natural Phenomena',
    'Famous Last Words of Historical Figures',
    'Intriguing Cults Throughout History',
    'Close Calls with Asteroids',
    'Most Isolated Communities on Earth',
    'Inventions Inspired by Nature',
    'Psychological Tricks That Really Work',
    'Bizarre Theme Parks Around the World',
    'Unusual Hobbies of Famous People',
    'Incredible Escapes from Prison',
    'Lost Technologies of Ancient Civilizations',
    'Most Valuable Items Ever Discovered',
    'Unexplained Sounds from the Deep Ocean',
    'Legendary Lost Cities',
    'Luckiest Discoveries by Ordinary People',
    'Most Expensive Mistakes in History',
    'Unexpected Friendships in History',
    'Amazing Facts About the Human Body',
    'Historical Myths Debunked',
    'Unbelievable Courtroom Stories',
    'Most Dangerous Roads in the World',
    'Bizarre Competitions Around the Globe',
    'Incredible Underwater Cities',
    'Hidden Cities: The World Beneath Our Feet',
    'Timeless Riddles of the Sphinx',
    'The Voynich Manuscript Enigma',
    'Napoleon\'s Lesser-Known Battles',
    'Secrets of the Silk Road',
    'The Lost Library of Alexandria',
    'Mysteries of the Nazca Lines',
    'The Real Robin Hood',
    'Influential Women in Ancient Times',
    'Easter Island: The Story Behind the Statues',
    'The Truth About Viking Culture',
    'The Great Emu War of Australia',
    'Historic Royal Fashion Faux Pas',
    'The Myth and Reality of King Arthur',
    'The Hidden Treasures of Timbuktu',
    'Unexpected Friendships Between World Leaders',
    'Famous Pets in History',
    'Lost Languages Still Undeciphered',
    'The Oldest Family Businesses Still Operating',
    'Historical Bans on Common Foods',
    'The Battle of Thermopylae',
    'Diplomacy During the Cuban Missile Crisis',
    'The Tactical Genius of the Battle of Trafalgar',
    'Secret Talks that Ended the Vietnam War',
    'The Siege of Leningrad\'s Unsung Heroes',
    'Near Miss: The 1914 Christmas Truce',
    'The Battle of Midway: Turning Point in the Pacific',
    'How Diplomacy Averted the Suez Crisis',
    'Intrigues of the Hundred Years\' War',
    'The Real Game of Thrones: The War of the Roses',
    'The Punic Wars: Rome vs. Carthage',
    'The Russo-Japanese War: Dawn of Asian Power',
    'Backdoor Diplomacy in the Korean War Armistice',
    'The Siege of Malta: Knights vs. Ottomans',
    'Battles Over the Holy City: Jerusalem\'s History',
    'The Falklands War: Controversy and Valor',
    'The Delicate Dance of the Camp David Accords',
    'The Conquests of Alexander the Great',
    'The Winter War: Finland\'s Resilience',
    'The Boer Wars: Guerrilla Tactics and Empire',
    'The Gulf War: Operation Desert Storm',
    'The Art of Diplomacy: Treaty of Versailles',
    'The Anzac Spirit: Gallipoli Campaign',
    'Waterloo: Napoleon\'s Last Stand',
    'The Berlin Crisis: Cold War Brinkmanship',
    'The Lost Kingdoms of Africa',
    'The Ghost Army of WWII',
    'The Forgotten Plague: Spanish Flu of 1918',
    'Sinking of the Wilhelm Gustloff: WWII\'s Deadliest Ship Disaster',
    'Tesla vs. Edison: The War of Currents',
    'The White Rose: Student Resistance Against Hitler',
    'The Great Molasses Flood: Boston\'s Sticky Tragedy',
    'The Dancing Plague of 1518',
    'The Mississippi Bubble: Financial Disaster in the 1700s',
    'Operation Mincemeat: WWII Deception Strategy',
    'The Orphan Trains of America',
    'The Year Without a Summer: 1816',
    'The Collapse of the Bronze Age',
    'The Christmas Island Nuclear Tests',
    'Eunuchs in Imperial Courts: Power Behind the Throne',
    'The Defenestrations of Prague',
    'The Acadian Expulsion: Le Grand Dérangement',
    'The Taiping Rebellion: China\'s Bloodiest Civil War',
    'The Children\'s Crusade: A Tragic Journey',
    'The Tulsa Race Massacre: A Forgotten Tragedy',
    'Vikings and Horned Helmets: Unraveling the Myth',
    'Flat Earth Beliefs: A Modern Resurgence',
    'Napoleon\'s Height: Debunking Historical Exaggerations',
    'The Full Moon Effect: Fact or Fiction?',
    'The Bermuda Triangle: Mythical Vortex or Sensational Fiction?',
    'Deconstructing the Legend of Atlantis',
    'The Truth About the Philosopher\'s Stone',
    'Common Misconceptions About Evolution',
    'The Lost City of El Dorado: Gold or Myth?',
    'The Dark Side of the Rainbow: Debunking the Pot of Gold',
    'Misconceptions About the Great Wall of China',
    'The Salem Witch Trials: Beyond the Hysteria',
    'The Legend of the Fountain of Youth',
    'The Myth of Vanishing Hitchhikers',
    'Roswell and UFOs: Alien Encounter or Cold War Cover-Up?',
    'The Reality of Ninjas: Separating Fact from Fiction',
    'The Curse of the Hope Diamond: Legend or Lore?',
    'The Trojan Horse: Historical Event or Mythical Tale?',
    'Misunderstood Pirates: Debunking Swashbuckling Stereotypes',
    'The Truth Behind Friday the 13th Superstitions',
    'Antibiotics Can\'t Cure Viruses: Why?',
    'The Importance of Biodiversity',
    'Vaccines: How Herd Immunity Works',
    'Photosynthesis: More Than Just Plant Food',
    'The Science of Tides',
    'Basic Genetics: Dominant vs Recessive Traits',
    'Why Do We Have Seasons?',
    'The Water Cycle: Earth\'s Recycling System',
    'Understanding Nutritional Labels',
    'The Role of Pollinators in Our Ecosystem',
    'How Soap Kills Germs',
    'The Reality of Climate Change',
    'Why We Need Sleep: A Scientific Look',
    'The Difference Between Weather and Climate',
    'Basic First Aid Knowledge',
    'Understanding Renewable Energy',
    'The Human Body: Organ Functions',
    'How the Internet Works',
    'The Basics of Maintaining Mental Health',
    'Intro to the Scientific Method',
    'Understanding Your Carbon Footprint',
    'Basic Astronomy: Our Place in the Universe',
    'The Food Pyramid: Balanced Diets',
    'Recycling: What Happens to Your Waste?',
    'Intro to Ecosystems: Interconnected Life',
    'The Power of Compounding Interest',
    'Importance of Work-Life Balance',
    'Building and Maintaining Credit Score',
    'The Value of Networking and Mentoring',
    'Investing in Retirement Early',
    'Continual Personal Development',
    'The Benefits of Traveling Young',
    'Maintaining Long-term Relationships',
    'Creating Multiple Income Streams',
    'The Importance of Mental Health',
    'Physical Fitness for Longevity',
    'Setting Realistic Goals',
    'Learning to Say No',
    'The Art of Negotiation',
    'Effective Time Management',
    'The Impact of Positive Thinking',
    'Embracing Failure as Part of Growth',
    'Cultivating Emotional Intelligence',
    'The Significance of Family Time',
    'Savings for Rainy Days',
    'Understanding Home Ownership',
    'The Rewards of Minimalism',
    'Volunteering and Community Service',
    'Pursuing Passion vs. Paycheck',
    'Developing a Personal Brand',
    'Healthy Eating Habits',
    'The Basics of Parenting',
    'Navigating Career Changes',
    'Overcoming the Fear of Rejection',
    'Appreciating the Journey, Not Just the Destination',
    'Practical Life Hacks',
    'The Unexplained Silence of the Eilean Mor Lighthouse Keepers',
    'The Enigma of the Bermuda Triangle',
    'The Mystery of the Lost Roanoke Colony',
    'Cryptic Messages of the Zodiac Killer',
    'The Puzzling Patterns of Crop Circles',
    'The Unsettling Phenomenon of Spontaneous Human Combustion',
    'The Hidden Depths of the Oak Island Money Pit',
    'The Perplexing Disappearance of Flight MH370',
    'Stonehenge: Monumental Mystery',
    'The Secret of the Voynich Manuscript',
    'Exploring the Concept of Time Travel',
    'Utopian vs. Dystopian Futures in Literature',
    'The Science Behind Teleportation',
    'Artificial Intelligence: Friend or Foe?',
    'The Ethics of Human Cloning',
    'Space Warfare: From Fiction to Reality?',
    'The Possibility of Parallel Universes',
    'Sci-Fi Predictions That Became Reality',
    'The Role of Cybernetics in Human Evolution',
    'Extraterrestrial Life in Science Fiction',
    'The Bystander Effect: Psychology of Inaction',
    'Cognitive Biases: Traps in Decision Making',
    'The Influence of Color on Emotion',
    'Deja Vu: Glitch in the Mind?',
    'The Psychology of Superstition',
    'Understanding the Herd Mentality',
    'Laughter: Benefits and Mechanisms',
    'The Impostor Syndrome Phenomenon',
    'Dreams: Windows to the Subconscious?',
    'The Impact of Birth Order on Personality',
    'Symbolism in Renaissance Art',
    'The Evolution of the English Language in Literature',
    'Art Movements That Changed the World',
    'The Lost Generation: Literature\'s Response to War',
    'Graffiti: Vandalism or Artistic Expression?',
    'Censorship: Banned Books Through History',
    'The Role of Mythology in Modern Literature',
    'The Golden Ratio in Art and Architecture',
    'Shakespeare\'s Influence on the English Language',
    'Eco-literature: Nature\'s Role in Writing',
    'Schrodinger\'s Cat and Quantum Mechanics',
    'Plato\'s Allegory of the Cave in Modern Society',
    'The Trolley Problem: Exploring Moral Dilemmas',
    'Eternal Recurrence: Nietzsche\'s Thought Experiment',
    'The Ship of Theseus: Identity Over Time',
    'Chinese Room Argument: AI and Understanding',
    'Brain in a Vat: Questioning Reality',
    'The Butterfly Effect: Chaos Theory and Life',
    'Zeno\'s Paradoxes: Motion and Infinity',
    'Simulated Reality: Are We Living in a Matrix?',
    'The Search for Dark Matter',
    'The Possibility of a Multiverse',
    'Black Holes and the Fabric of Spacetime',
    'The Future of Space Tourism',
    'Colonizing Mars: Challenges and Prospects',
    'The Mystery of Fast Radio Bursts',
    'Voyager Probes: Messages from Earth',
    'Gravitational Waves: Echoes of Cosmic Events',
    'The Role of Comets in Understanding the Solar System',
    'The Goldilocks Zone: Search for Earth-like Planets',
    'High-Intensity Interval Training: Pros and Cons',
    'Yoga\'s Effects on Mental Health',
    'The Science Behind Muscle Memory',
    'Running: Its Impact on the Brain',
    'The Role of Protein in Muscle Recovery',
    'Functional Fitness for Daily Life',
    'The Benefits of Aquatic Exercise',
    'Mindfulness in Fitness: Body-Mind Connection',
    'The Myth of Spot Reduction in Fat Loss',
    'Overtraining: Recognizing the Signs',
    'The Rise of Populism in the 21st Century',
    'Climate Change Policies: Global Comparison',
    'The Impact of Social Media on Elections',
    'Universal Basic Income: Prospects and Challenges',
    'The Future of the European Union',
    'Global Water Scarcity and Potential Solutions',
    'The Role of the UN in Today\'s World',
    'Cybersecurity and National Defense',
    'The Pros and Cons of Globalization',
    'Artificial Intelligence: Ethical and Political Debate',
    'Big Changes from Small Actions via the Butterfly Effect',
    'Ignoring Facts: The Role of Cognitive Dissonance',
    'Mental Health Transformation with Mindfulness',
    'Our Cosmic Place in the Multiverse Theory',
    'Empathy\'s Power in Relationships',
    'Freedom in Minimalism\'s Simplicity',
    'Quantum Physics\' Challenge to Reality',
    'Ethical Debates in Artificial Intelligence',
    'Universal Basic Income Explored',
    'Cryptocurrency\'s Finance Revolution',
    'Personal Growth Through Failure',
    'Sustainable Living\'s Broader Impact',
    'Social Media\'s Behavioral Impact',
    'Animal Intelligence Explored',
    'Philosophical Side of Space Exploration',
    'True Happiness in Psychology',
    'Beyond Judgments with Cultural Relativism',
    'Ready for the Fourth Industrial Revolution?',
    'Uncertainty and Control\'s Illusion',
    'Immortality\'s Ethics in Life Extension',
    'Unpredictability in Chaos Theory',
    'Solitude\'s Role in Self-Discovery',
    'Conscious Control in Lucid Dreaming',
    'Brain Reshaping via Neuroplasticity',
    'Stoicism\'s Modern Relevance',
    'Quantum Computing\'s New Frontier',
    'The Power of Gut Trust',
    'Perception Shifts in Virtual Reality',
    'Intelligence Redefined by Plant Sentience',
    'The Cosmic Web\'s Galactic Journey',
    'Taste Alteration Through Sound',
    'Infinite Jest and Laughter\'s Psychology',
    'Mandalas: Between Spirituality and Art',
    'Nature\'s Complexity in Fractals',
    'Synesthesia\'s Sensory Blend',
    'Dreamscape Mastery via Lucid Dreaming',
    'Astronauts\' Euphoria in Space',
    'Big Changes from Small Actions via the Butterfly Effect',
    'Ignoring Facts: The Role of Cognitive Dissonance',
    'Mental Health Transformation with Mindfulness',
    'Our Cosmic Place in the Multiverse Theory',
    'Empathy\'s Power in Relationships',
    'Freedom in Minimalism\'s Simplicity',
    'Quantum Physics\' Challenge to Reality',
    'Ethical Debates in Artificial Intelligence',
    'Universal Basic Income Explored',
    'Cryptocurrency\'s Finance Revolution',
    'Personal Growth Through Failure',
    'Sustainable Living\'s Broader Impact',
    'Social Media\'s Behavioral Impact',
    'Animal Intelligence Explored',
    'Philosophical Side of Space Exploration',
    'True Happiness in Psychology',
    'Beyond Judgments with Cultural Relativism',
    'Ready for the Fourth Industrial Revolution?',
    'Uncertainty and Control\'s Illusion',
    'Immortality\'s Ethics in Life Extension',
    'Unpredictability in Chaos Theory',
    'Solitude\'s Role in Self-Discovery',
    'Conscious Control in Lucid Dreaming',
    'Brain Reshaping via Neuroplasticity',
    'Stoicism\'s Modern Relevance',
    'Quantum Computing\'s New Frontier',
    'The Power of Gut Trust',
    'Perception Shifts in Virtual Reality',
    'Intelligence Redefined by Plant Sentience',
    'The Cosmic Web\'s Galactic Journey',
    'Taste Alteration Through Sound',
    'Infinite Jest and Laughter\'s Psychology',
    'Mandalas: Between Spirituality and Art',
    'Nature\'s Complexity in Fractals',
    'Synesthesia\'s Sensory Blend',
    'Dreamscape Mastery via Lucid Dreaming',
    'Astronauts\' Euphoria in Space',
    'Nature vs Nurture: Shaping Human Behavior',
    'Fiction vs Reality: Where Lines Blur',
    'Science vs Superstition: Conflicting Beliefs',
    'Human Intelligence vs Artificial Intelligence',
    'Democracy vs Totalitarianism: Governance Extremes',
    'Work-Life Balance vs Career Growth',
    'Renewable Energy vs Fossil Fuels: The Future of Power',
    'Free Speech vs Hate Speech: Drawing the Line',
    'Globalization vs Localization: Economic Strategies',
    'Traditional Education vs Online Learning',
    'Quantum Computing vs Classical Computing',
    'Veganism vs Omnivorism: Dietary Impacts',
    'Private vs Public: Healthcare Systems Compared',
    'Digital Currency vs Traditional Money',
    'Monogamy vs Polyamory: Relationship Structures',
    'Introverts vs Extroverts: Social Preferences',
    'Capitalism vs Socialism: Economic Ideologies',
    'Books vs E-Books: The Reading Debate',
    'Professional Career vs Entrepreneurship',
    'City Life vs Country Life: Lifestyle Choices',
    'Creating and Sticking to a Budget',
    'Basic Cooking Skills',
    'Effective Grocery Shopping',
    'Time Management Techniques',
    'Self-Care Practices',
    'Navigating Health Insurance',
    'Understanding Credit Scores',
    'Basic Car Repairs',
    'Professional Email Etiquette',
    'Job Interview Skills',
    'Renting vs. Buying: Making Informed Decisions',
    'Mental Health: Recognizing and Seeking Help',
    'Basic Home Repairs',
    'Networking Skills',
    'Personal Safety Precautions',
    'Conflict Resolution Strategies',
    'Public Transportation Tips',
    'Laundry and Clothing Maintenance',
    'Healthy Relationship Boundaries',
    'Negotiating Salary and Benefits',
    'Social Media Dos and Don\'ts',
    'Fundamentals of Investing',
    'Stress Management Techniques',
    'Understanding Contracts',
    'First Aid Basics',
    'Setting Professional Goals',
    'Sustainable Living Practices',
    'Fitness Routines for Busy Schedules',
    'Travel Planning on a Budget',
    'Basic Legal Rights',
    'Understanding Mortgages',
    'Civic Engagement and Voting',
    'Meal Planning and Prepping',
    'Dealing with Rejection',
    'Workplace Rights and Responsibilities',
    'Basic Computer Troubleshooting',
    'Home Fire Safety',
    'Effective Communication Skills',
    'Debt Management Strategies',
    'Cultural Competency and Diversity',
    'Retirement Planning Basics',
    'Critical Thinking in the Information Age',
    'Navigating Government Services',
    'Basic Sewing for Clothing Repairs',
    'Parenting Basics for New Parents',
    'Understanding Nutritional Labels',
    'Automobile Insurance Essentials',
    'Handling Workplace Conflicts',
    'Moving and Relocation Tips',
    'Emergency Preparedness',
];




export const returnTopics = async () => {
    const topicsList = [...staticTopicsList];
    let selectedTopics = [];

    const selectRandomTopic = () => {
        let randomIndex = Math.floor(Math.random() * topicsList.length);
        let selectedTopic = topicsList[randomIndex];
        topicsList.splice(randomIndex, 1);
        return { 'content': selectedTopic };
    };

    for (let i = 0; i < 6; i++) {
        selectedTopics.push(selectRandomTopic());
    }

    return selectedTopics;
};






const aiMessageList = [
    'and further down the rabbit hole we go...',
    'stay curious, stay foolish...',
    'hmmm, that makes me wonder about...',
    'feed your mind, starve your stereotypes...',
    'knowledge is power, you grow powerful',
    'ingnorance is bliss, but bliss is overrated',
    'there are no dumb questions, just dumb ai',
    'let your curiosity be your guide...',
    'the more you know ...',
    'learn more, wonder less...',
    'curiosity didn\'t kill the cat, that\'s a myth...',
    'think, explore, repeat...',
    'take a step back, and think about the big picture...',
    'dive a little deeper...',
    'onward, to realms of new wisdom...',
    'venture forth, intrepid learner...',
    'you\'ve unlocked a new level in your mind...',
    'will there be a test on this? Yes and "everything" will be on it.',
    'brains love brain food. Feast away!',
    'achievement unlocked: brainpower boosted.',
    'life\'s a test, and everything\'s on it. study up!',
    'expand your brain, or it\'ll start shrinking!',
    'never stop learning...',
    'ai\'s have 7 billion parameters, how about you?',
    'binge-learning: like streaming, but for champions.'

];



// export const aibanter = async () => {
//     const topicsList = [...aiMessageList];
//     let selectedTopics = [];

//     const selectRandomTopic = () => {
//         let randomIndex = Math.floor(Math.random() * topicsList.length);
//         let selectedTopic = topicsList[randomIndex];
//         topicsList.splice(randomIndex, 1);
//         return { 'content': selectedTopic };
//     };

//     for (let i = 0; i < 1; i++) {
//         selectedTopics.push(selectRandomTopic());
//     }

//     return selectedTopics;
// };







function createMessageIterator(messages) {
    let index = 0;
    return function() {
        if(index >= messages.length) {
            index = 0; // or remove this line to stop after the last message
        }
        return messages[index++];
    }
}

export const getNextMessage = createMessageIterator(aiMessageList);


