import React, { useState, useEffect } from 'react';
import { checkUserAuthentication } from './apis';
import { getProfile } from './BaseApi';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Modal from '../elements/Modal.js';
import './UserProfile.css';

const UserProfile = () => {
    const [email, setEmail] = useState('loading...'); // Placeholder email
    const [credits, setCredits] = useState('loading...'); // Placeholder credits
    const [isEditing, setIsEditing] = useState(false);
    const [editedEmail, setEditedEmail] = useState(email);
    const [user, setUser] = useState();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        checkUserAuthentication(setUser)

    }, []);

    useEffect(() => {
        if (user?.signInUserSession?.accessToken?.jwtToken) {
            getProfile(user?.signInUserSession?.accessToken?.jwtToken).then(resp => {
                setEmail(resp?.email);
                setCredits(resp?.credits);
            });


        }
    }, [user]);
    const handleDelete = async () => {
        setShowModal(true)

    };
    // const handleEdit = async () => {
    //     setIsEditing(true);
    // };

    const handleSave = () => {
        setEmail(editedEmail);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setEditedEmail(email);
        setIsEditing(false);
    };
    const handleModalClose = (buttonClicked) => {
        setShowModal(false);
        if (buttonClicked === 'DELETE') {
            try {
                Auth.deleteUser(user);
                console.log('Account deleted successfully');
                navigate('/')
            } catch (error) {
                console.error('Error deleting account:', error);
                // Handle the error appropriately
            }
        }
    };

    return (
        <div className="user-profile">
            <Modal
                isOpen={showModal}
                onClose={handleModalClose}
                message="Deleting your account is irreversible and erases all your data. "
                buttons={['DELETE', 'CANCEL']}
            />
            <div className="user-profile-header">
                <div className="user-icon"></div>
                <div className="user-name">User Account</div>
            </div>
            <div className="content">
                <div className="section">
                    <label>Email:</label>
                    {!isEditing ? (
                        <>
                            <span>{email}</span>
                            {/* <button onClick={handleEdit}>Edit</button> */}
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                value={editedEmail}
                                onChange={(e) => setEditedEmail(e.target.value)}
                            />
                            <button onClick={handleSave}>Save</button>
                            <button onClick={handleCancel}>Cancel</button>
                        </>
                    )}
                </div>
                <div className="section">
                    <label>Credits:</label>
                    <span>{credits}</span>
                    <button className="user-profile-button" onClick={() => { navigate(`/pricing?appname=general`) }}>Buy Credits</button>
                </div>
                <div className="section">
                    <label>Delete Account:</label>
                    <span></span>
                    <button className="user-profile-button" onClick={handleDelete}>Delete</button>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;