import React, { useState,useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './ProfileDropdown.css';


const ProfileDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isMember, setIsMember] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

     
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
                setUser(userInfo);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUser();
    }, [location, isOpen]);

    useEffect(() => {
        setIsMember(user?.attributes?.['custom:member'] === '1');
    }, [user, isOpen]);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleUpgrade = (event) => {
        event.preventDefault();
        toggleDropdown();
        navigate('/membership', { state: location });
    };


    const goTo = (url) => {
        if (url.startsWith('https://')) {
            window.open(url, '_blank');
        } else {
            window.location.href = `${window.location.origin}/${url}`
            // navigate(`/${url}`, { state: location });
            
        }

        toggleDropdown();
    };

    const handleLogin = (event) => {
        event.preventDefault();
        toggleDropdown();
        navigate('/signin', { state: location });
    };

    const handleLogout = async (event) => {
        event.preventDefault();
        toggleDropdown();
        try {
            await Auth.signOut();
            setUser(null);
            navigate('/');
        } catch (error) {
            setUser(null);
            navigate('/');
        }
    };
    // background-image: url('/public/logo.png');
    return (
        <div className="profile-dropdown">
            <div onClick={toggleDropdown} className="profile-dropdown-toggle profile-icon">
                {/* <span>
                    <img className="profile-icon" src="profile_icon.png"/>
                </span> */}
            </div>
            {isOpen && (
                <div className="profile-dropdown-menu">
                    <ul className="profile-dropdown-menu-list">

                        <li className="profile-dropdown-item" onClick={() => goTo('')}><label className="profile-dropdown-label">home</label></li>
                        {user ? (
                            <div>
                            <li className="profile-dropdown-item" onClick={() => goTo('account')}><label className="profile-dropdown-label" >account</label></li>
                            <li className="profile-dropdown-item" onClick={handleLogout}><label className="profile-dropdown-label" >log out</label></li>
                            </div>
                        ) : (
                            <li className="profile-dropdown-item" onClick={handleLogin}><label className="profile-dropdown-label" >register/log in</label></li>
                        )}

                        {/* <li className="profile-dropdown-item"><label className="profile-dropdown-label">who made this?</label></li> */}
                        <li className="profile-dropdown-item" onClick={() => goTo('#feedback')}><label className="profile-dropdown-label">feedback</label></li>
                        {/* <li className="profile-dropdown-item" onClick={() => goTo('https://www.buymeacoffee.com/saneilsuri')}><label className="profile-dropdown-label">buy me a coffee</label></li> */}

                        {/* { !isMember && (<li className="profile-dropdown-item">
                            <label className="profile-dropdown-label" onClick={handleUpgrade}>upgrade</label>

                        </li>)} */}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ProfileDropdown;