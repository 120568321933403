
import axios from 'axios';
// import { Auth } from '@aws-amplify/auth';
// import ReactGA from 'react-ga4';


const apiUrl = process.env.REACT_APP_API_URL || 'https://oltacivb0e.execute-api.us-west-1.amazonaws.com/botchat-stage';
const endpoint = apiUrl + '/didactic'; 

// Abstract common logic for making the API call
const makeApiRequest = async (params) => {
    console.log('params', params)
    try {
        const response = await axios.get(endpoint, { params });
        console.log('response', response)
        return response;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
};



export const getCategories = async () => {
    const params = {
        'action': 'get_categories',
        'items_to_return': 3
    };
    const response = await makeApiRequest(params);
    return response?.data?.categories
};

export const getTopics = async (category) => {
    console.log('category', category)
    const params = {
        'action': 'get_topics',
        'category': category
    };
    const response = await makeApiRequest(params);
    console.log('get topics response', response?.data?.topics)
    return response?.data?.topics;
};


export const getTopicsTemp = async (count) => {
    return [{ 'content': 'Sustainable Gardening', 'description': 'Learn how to create a sustainable garden, conserve resources, and reduce your environmental footprint while enjoying the benefits of gardening.' },
    { 'content': 'Basics of Personal Finance', 'description': 'Explore essential concepts of personal finance, including budgeting, saving, investing, and managing debt to secure your financial future.' },
    { 'content': 'Introduction to Meditation', 'description': 'Discover the art of meditation, its various techniques, and how it can reduce stress, improve mental clarity, and promote overall well-being.' },
    { 'content': 'Basics of Digital Photography', 'description': 'Dive into the fundamentals of digital photography, covering composition, lighting, camera settings, and editing to capture stunning images with your camera or smartphone.' },
    { 'content': 'Nutrition for Healthy Living', 'description': 'Gain insights into balanced nutrition, meal planning, and dietary choices to enhance your health, boost energy levels, and maintain a well-rounded lifestyle.' },
    { 'content': 'Introduction to Coding', 'description': 'Start your coding journey by learning the basics of programming, understanding algorithms, and writing your first lines of code to unlock the world of software development.' }]

};


export const getTopicContentTemp = async (pk, sk, i) => {

    return {
        'data': {
            'PK': '9c995019-297e-4fc3-abe0-f727b2ce88cb',
            'SK': 'c8d18aac-6516-4c0e-aa0b-437ecb020133',
            'created_at': '2023-10-13 01:11:48.442702',
            'status': 'complete',
            'topic': 'Sustainable Gardening',
            'description': 'Learn how to create a sustainable garden, conserve resources, and reduce your environmental footprint while enjoying the benefits of gardening.',
            'knowledge': [
                { "Risk Understanding": "In startups, risk understanding is critical because the innovative nature of startups means they operate in an environment of high uncertainty. Risk could stem from multiple sources including technological, market, financial, and operational. Understanding these risks helps startups prepare for possible challenges." },
                { "Risk Management": "Risk management for startups involves identifying, assessing, and prioritizing risks followed by the application of resources to minimize, monitor, and control the impact of these risks. This can involve diversifying the product range, seeking multiple funding sources, effectively branding and marketing the product, and applying effective financial and operational management." },
                { "Mitigating Risks": "Mitigation of risks in startups involves carefully strategizing to offset potential threats. This could include developing a strong business model, thorough market research, effectively managing resources, securing intellectual property, and maintaining good relations with stakeholders such as investors, employees, and customers." },
                { "Preparedness for Change": "Since startup environments are volatile, being prepared for change is key. This involves understanding the market dynamics, staying updated with latest technologies, maintaining financial flexibility, adapting to changing customer needs and regulations and pivoting the business model if required." }
            ],
            'updated_at': '2023-10-13 01:11:48.442702',
            'user_id': 'Guest'
        }
    }
};

export const submitKnowledge = async (input, request_format, user_id) => {
    const params = {
        'action': 'submit_knowledge',
        'input': input,
        'request_format': request_format,
        'user_id': user_id
    };
    return await makeApiRequest(params);
};

export const getKnowledge = async (pk, sk, i) => {
    const params = {
        'action': 'get_knowledge',
        'pk': pk,
        'sk': sk,
        'i': i
    };

    return await makeApiRequest(params);

};




