import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import styles from './MiniApp.css'; // Assuming you have this CSS module
import './MiniApp.css';


const MiniAppCarousel = ({ miniApps }) => {
    const navigate = useNavigate();
    useEffect(() => {
        miniApps.forEach((app) => {
            const img = new Image();
            img.src = app.imageUrl;
        });
    }, [miniApps]);

    const NavigateToApp = (path) => {
        navigate(path)
    }
    
    const NavigateToPricing = (event, appName) => {
        event.stopPropagation();
        navigate(`/pricing?appname=${encodeURIComponent(appName)}`);
    }
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-next-arrow`}
                onClick={onClick}
            />
        );
    };

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} custom-prev-arrow`}
                onClick={onClick}
            />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 768, // Adjust breakpoint as needed
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className='slider-container'>
            <Slider {...settings}>
                {miniApps.map((app, index) => (
                    <div key={index} className="carouselItem" onClick={() => NavigateToApp(app.path)}>
                        <div className="image-container">
                            <img src={app.imageUrl} alt={app.name} className="appImage" />
                        </div>
                        <div className="appName">{app.name}</div>
                        <div className="appDescription">{app.description}</div>
                        {app.pricing && (<button className="app-card-pricing" onClick={(event) => NavigateToPricing(event, app.pricing)}  >See Pricing</button>)}
                        
                        {/* <div className="buttons">
                            <button className="previewButton">Preview</button>
                            <button className="goToAppButton" onClick={() => NavigateToApp(app.path)} >Go to App</button>
                        </div> */}
                    </div>
                ))}

            </Slider>
        </div>
    ); 
};

export default MiniAppCarousel;