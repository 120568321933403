import './Conversation.css';
import Modal from '../elements/Modal.js';
import React, { useEffect, useState } from 'react';
import { getConversation, checkUserAuthentication, createConversationConfig, extendConversationConfig, callSendAudio, deleteConversationMessage } from './apis.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfile } from '../base/BaseApi';

function Conversation() {
  const { state } = useLocation();
  const { config } = state;
  const [messages, setMessages] = useState([]);
  let loopCount = 0;
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isAppending, setIsAppending] = useState(true);
  const [audioProcessing, setAudioProcessing] = useState(false);
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState(null);
  const [stateComplete, setStateComplete] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const location = useLocation();
  const [credits, setCredits] = useState();

  const handleModalClose = (buttonClicked) => {
    setShowModal(false);
    if (buttonClicked === 'Yes') {
      setStateComplete(false);
      setAudioProcessing(false);
      callSendAudio(config.PK, config.SK, user?.signInUserSession?.accessToken?.jwtToken)
      setCredits(credits-1);
      setStateComplete(true);
    }
  };

  const handleCreditsModalClose = (buttonClicked) => {
    setShowCreditsModal(false);
    if (buttonClicked === 'Yes') { navigate('/pricing?appname=aivsai', { state: { pathname: location.pathname } }) }
    // navigate(`/conversation`, { state: { config: conversationConfig.data.conversation_config }, from: '/conversation' })
  };
  useEffect(() => {
    checkUserAuthentication(setUser);
  }, []);
  useEffect(() => {
    // if (setup_intent !== null && setup_intent !== undefined) {

    setTimeout(refreshCredits, 500);
    // }; 
    refreshCredits()
  }, [user]);

  useEffect(() => {
    // const messageCount = 0;
    const maxLoopCount = 30;
    const intervalTime = 3000;
    const intervalId = setInterval(() => {
      if (!stateComplete && loopCount < maxLoopCount) {
        loopCount++;
        getConversation(config.PK, config.SK, user.username, user.signInUserSession.accessToken.jwtToken)
          .then(response => {
            const newMessages = response?.messages;
            setStateComplete(response?.config?.status === 'complete')
            // console.log('messageCount', messageCount)
            // console.log('message lenght check', messages.length, response?.config?.message_limit)
            if (newMessages.length <= response?.config?.message_limit) {
              newMessages.push({ 'chatbotName': false, 'message': '...' });
            }


            if (newMessages && JSON.stringify(newMessages) !== JSON.stringify(messages)) {
              setLoader(false);
              setMessages(newMessages);
              console.log(newMessages);
            }
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        setIsAppending(false);
        clearInterval(intervalId);
      }
    }, intervalTime);

    return () => clearInterval(intervalId);
  }, [messages, config, loopCount, user, stateComplete, isAppending]);

  useEffect(() => {
    const messageIntervalId = setInterval(() => {
      if (currentMessageIndex < messages.length - 1) {
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
        setAudioProcessing(true);
      } else {
        clearInterval(messageIntervalId);
      }
    }, 1000);

    return () => clearInterval(messageIntervalId);
  }, [messages, currentMessageIndex]);

  const refreshCredits = () => {
    if (user?.signInUserSession?.accessToken?.jwtToken) {
      getProfile(user?.signInUserSession?.accessToken?.jwtToken).then(resp => {
        setCredits(resp?.credits);
      });
    }
  }

  const handleReturn = async (e) => {
    navigate('/aivsai', { state: { config } })
  }

  const handleAudio = async (e) => {
    await refreshCredits();
      if (credits > 0) {
        setShowModal(true);
      }
      else {
        setShowCreditsModal(true);
        // handleReturn(null);
      }
    }


  const handleExtend = async (e) => {
    // if (user?.attributes['custom:member'] === '1') {
    setStateComplete(false)
    config.message_limit = config.message_limit + 1
    const token = user?.signInUserSession.accessToken.jwtToken ?? ''
    extendConversationConfig(config.PK, config.SK, token).then(response => {
      setStateComplete(response?.config?.status === 'complete')
    })
      .catch(error => {
        console.error(error);
      });
    setIsAppending(false);
    setLoader(true)
    loopCount = 0;
    // }
    // else { setShowModal(true) }

  }

  const handleDelete = async (e) => {
    setStateComplete(false)
    console.log('pk', messages[currentMessageIndex].PK)
    const token = user?.signInUserSession.accessToken.jwtToken ?? ''

    deleteConversationMessage(messages[currentMessageIndex].PK, messages[currentMessageIndex].SK, token).then(response => {
      setMessages(messages.slice(0, -1));
      setCurrentMessageIndex(currentMessageIndex - 1)
      setStateComplete(true)
    })
      .catch(error => {
        console.error(error);
        setStateComplete(true)
      });
    // }
    // else { setShowModal(true) }

  }
  const handleRetry = async (e) => {
    // if (user?.attributes['custom:member'] === '1') {

    setStateComplete(false)
    setMessages([])
    setLoader(true)

    const token = user?.signInUserSession.accessToken.jwtToken ?? ''

    createConversationConfig(
      config.chatbot1['PK'], config.chatbot2['PK'], config.topic, config.user_id, token
    ).then(response => {
      navigate(`/conversation`, { state: { config: response.data.conversation_config }, from: '/conversation' })
    }).catch(error => {
      console.error(error);
    });
    // }
    // else { setShowModal(true) }
  }



  return (

    <div className="conversation-container">

      <div className="min-height ">

        <Modal
          isOpen={showModal}
          onClose={handleModalClose}
          message={`You have ${credits} left. You can use 1 credit to create an audio version of this conversation. It will be emailed to you in 5-10 minutes. Continue?`}
          buttons={['Yes', 'No']}
        />
        <Modal
          isOpen={showCreditsModal}
          onClose={handleCreditsModalClose}
          message="You are out of case credits. Do you wish to purchase some?"
          buttons={['Yes', 'No']}
        />
        {messages && messages.map((message, index) => (
          <div key={index} className={index % 2 === 0 ? 'message-left' : 'message-right'}>
            <div className={
              (index % 2 === 0 ? 'message-content-left' : 'message-content-right') +
              (index <= currentMessageIndex ? ' animated' : '') +
              (index > currentMessageIndex ? ' hidden' : '')
            }>
              {message.chatbot_name && <strong>{message.chatbot_name}: </strong>}
              {message.message}
            </div>
          </div>
        ))}
        {loader && <div><div className="loader"></div></div>}
      </div>
      <div className="button-container">
        <button className="button-class-conversation" type="button" onClick={handleReturn}> &#x2190;Back</button>
        <button className="button-class-conversation" type="button" disabled={!stateComplete} onClick={handleDelete}> &#x2191;Delete</button>
        {/* <button className="button-class-conversation" type="button" disabled={!stateComplete} onClick={handleRetry}> &#x21bb; Restart </button> */}
        <button className="button-class-conversation" type="button" disabled={!stateComplete} onClick={handleExtend}> &#x2193; Extend </button>
        <button className="button-class-conversation" type="button" disabled={!stateComplete || !audioProcessing} onClick={handleAudio}> &#127911;Get Audio</button>
      </div>
    </div>
  );
}

export default Conversation;