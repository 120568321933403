import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ color: 'black' , fontSize: '16px', fontFamily: 'Arial' }}>
            <h1 style={{ color: 'black', fontFamily: 'Arial' }}>Privacy Policy (Funcanny AI)</h1>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}><strong>Effective Date:</strong> 7/25/2023</p>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Thank you for using Funcanny AI. This Privacy Policy explains how we collect, use, and protect your personal information when you use our application. By accessing or using our app, you consent to the practices described in this policy.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>1. Information We Collect</h2>
            <ul>
                <li>Account Information: When you create an account, we may ask for your name, email address, phone number.</li>
                <li>Message Content: We store the information and content you send and receive through our app for the purpose of delivering our services.</li>
                <li>Device Information: We may collect information about the device you use to access our app, such as device type, operating system, and unique device identifiers.</li>
                <li>Payment Information: We collect and store your payment information and address submitted by you only as long as necessary for the processing of payments and for compliance with our legal obligations. We take appropriate measures to ensure the security and confidentiality of your data.</li>
                
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>2. Use of Information</h2>
            <ul>
                <li>Provide Services: We utilize the information you provide to operate our range of web applications, as well as to continually improve the functionality of our apps and AI.</li>
                <li>Communication: We may send you important updates, notifications, or promotional messages related to our services.</li>
                <li>Customer Support: Your information helps us respond to your inquiries and provide customer support.</li>
                <li>Analytics: We may analyze user behavior to enhance app performance and user experience.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>3. Data Retention</h2>
            <ul>
                <li>We retain your account information as long as your account is active or as needed to provide you with our services.</li>
                <li>Message content may be stored temporarily for delivery purposes, but we do not retain it once the message is delivered, unless you explicitly save it.</li>
                <li>We may retain aggregated and anonymized data for analytical purposes.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>4. CCPA (California Consumer Privacy Act) Rights</h2>
            <ul>
                <li>Right to Access: You have the right to request access to the personal information we hold about you.</li>
                <li>Right to Deletion: You can request the deletion of your personal information that we have collected, subject to legal exceptions.</li>
                <li>To exercise your CCPA rights, you can make a verifiable request through our contact details provided below.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>5. Data Security</h2>
            <ul>
                <li>We implement reasonable security measures to protect your information from unauthorized access, disclosure, or modification.</li>
                <li>However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>6. Data Sharing with AI Partners</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Our app may contain links to third-party websites or services. We are not responsible for their privacy practices, and you should review their policies separately. </p>



            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>6. Third-Party Services</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>We may share what you type into this service with companies that help us run the AI part of the service. By using our AI judge, you're okay with us sending your information to these companies. Check our Privacy Policy to learn more about how we take care of your details. </p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>7. Children's Privacy</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Our app is not intended for use by individuals under 18. If you are a parent or guardian and believe we have collected information from a child, please contact us to have the data removed.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>8. Changes to Privacy Policy</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the revised policy. Please check back periodically for updates.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>9. Contact Us</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>If you have any questions or concerns about this Privacy Policy, or if you want to exercise your CCPA rights, please contact us at <a href="mailto:hello@funcannyai.com">hello@funcannyai.com</a>.</p>

            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>By using Funcanny AI, you signify your acceptance of this Privacy Policy. If you do not agree with this policy, please do not use our app.</p>

            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Thank you for trusting us with your privacy.</p>
        </div>
    );
};

export default PrivacyPolicy;
