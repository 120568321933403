import React from 'react';
import './Footer.css';
// import { sendEventLog } from './apis';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <p>Email: <a href="mailto:hello@funcannyai.com">hello@funcannyai.com</a></p>

      </div>
      <div className="social-media-links">
      </div>
      <div className="legal-info">
        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service|</a>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <div className="legal-info">
        </div>
      </div>

      <a className="footer-socialmedia" href="https://www.instagram.com/funcannyai/" target="_blank" rel="noopener noreferrer">
        <img src="instagram-icon.png" alt="Instagram" width="32" height="32"></img>
      </a>
      <a className="footer-socialmedia" href="https://www.linkedin.com/company/funcanny-valley-ai/about/?" target="_blank" rel="noopener noreferrer">
        <img src="linkedin-icon.png" alt="LinkedIn" width="32" height="32"></img>
      </a>
      <a className="footer-socialmedia" href="https://www.buymeacoffee.com/saneilsuri" target="_blank" rel="noopener noreferrer">
        <img src="icons_logos/bmc-logo.png" alt="BuyMeACoffee" width="32" height="32"></img>
      </a>
      <nav>
      </nav>

    </footer>
  );
};

export default Footer;