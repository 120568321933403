import { useEffect, useState } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import CustomSignIn from './CustomSignIn';

const ProtectedRoute = ({ element: Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
        navigate('/signin',  { state:location});
      }
    };

    checkAuthState();
  }, [navigate, location]);

  return isAuthenticated ? Element : <CustomSignIn/>;
};

export default ProtectedRoute;
