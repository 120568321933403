import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { returnTopics, getNextMessage } from './Topics';
import { getKnowledge, submitKnowledge } from './DidactApi';
import { checkUserAuthentication } from '../base/apis';
import Modal from '../elements/Modal';
import './CuriosityQuest.css';

const CuriosityQuest = () => {
    const [transcript, setTranscript] = useState([]);
    const [id, setId] = useState(1);
    const [user, setUser] = useState(null);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [interfaceRecord, setInterfaceRecord] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const chatScrollLocation = useRef(null);

    const appendToTranscript = (user, contentType, content, contentParameter, contentDisplay) => {
        const newMessage = {
            id: id,
            user: user,
            contentType: contentType,
            content: Array.isArray(content) ? content : [content],
            contentParameter: contentParameter,
            contentDisplay: contentDisplay
        };
        setTranscript(prevTranscript => [...prevTranscript, newMessage]);
    };

    useEffect(() => {
        if (chatScrollLocation.current) {
            const { scrollHeight, clientHeight } = chatScrollLocation.current;
            const maxScrollTop = scrollHeight - clientHeight;
            chatScrollLocation.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }, [transcript]);


    useEffect(() => {
        checkUserAuthentication(setUser);
        if (transcript.length === 0) {
            getContentOptions();
        }
    }, []);


    useEffect(() => {
        const current = transcript[transcript.length - 1];
        setCurrentRecord(current);
        if (current?.user === 'interface') {
            setInterfaceRecord(current);
        }
    }, [transcript]);

    useEffect(() => {
        if (functionMap[currentRecord?.contentType]) {
            functionMap[currentRecord.contentType](currentRecord);
        }
    }, [currentRecord]);

    const getContentOptions = () => {
        returnTopics()
            .then(response => {
                setTranscript([]);
                appendToTranscript('teacher', 'response', [{ 'content': 'What are you curious to learn about today?' }], 'content', true)
                appendToTranscript('interface', 'selectors', response, 'content', false);
                setId(prevId => prevId + 1);
            })
            .catch(error => {
                console.error('Error fetching topics:', error);
            });
    };

    const onTopicSelect = (record) => {
        if (user) {
            appendToTranscript('student', 'submitKnowledge', record, 'content', true);
            setId(prevId => prevId + 1);
            setInterfaceRecord(null);


        } else {
            setShowModal(true);
        }
        
    };

    const handleCustomMessage = (message) => {
        if (message.length > 0) {
            if (user) {
                appendToTranscript('student', 'submitKnowledge', [{ 'content': message }], 'content', true);
            setInterfaceRecord(null);
    
    
            } else {
                setShowModal(true);
            }
            
        }
    };


    const handleRefreshTopics = () => {
        setInterfaceRecord(null);
        getContentOptions()
        // appendToTranscript('system', 'contentOptions', [{ 'count': 3 }], 'count', false);

    };


    const callGetKnowledge = async (record) => {
        setIsProcessing(true);
        let complete = false;
        let counter = 1
        while (!complete) {
            complete = await fetchContent(record);
            await new Promise(resolve => setTimeout(resolve, 5000));
            counter = counter + 1
            if (counter > 25) {
                complete = true;
            }
        }
        setIsProcessing(false);
    };


    const fetchContent = async (record) => {
        try {
            const response = await getKnowledge(record.content[0].PK, record.content[0].SK, 0);
            const contentObject = response?.data?.knowledge;
            if (contentObject?.status === 'complete') {
                try {
                    const contentText = JSON.parse(contentObject?.knowledge);
                    appendToTranscript('teacher', 'response', contentText?.explanation, 'content', true);

                    appendToTranscript('teacher', 'response', [{ 'content': getNextMessage() }], 'content', true);
                    appendToTranscript('interface', 'selectors', [...contentText?.related_topics, ...contentText?.socratic_questions], 'content', false);

                    return true;
                } catch (error) {
                    console.error(error);
                    return true;
                }
            }
        } catch (error) {
            console.error(error);
            return false;
        }
        return false
    };

    const handleModalClose = (buttonClicked) => {
        setShowModal(false);
        if (buttonClicked === 'Ok') { navigate('/signin', {state:{}}) }
    };

    const callSubmitKnowledge = (record) => {
        const topic = record.content[0][record.contentParameter]
        submitKnowledge(topic, 'topic', user?.username ?? 'Guest')
            .then(response => {
                appendToTranscript('system', 'getKnowledge', response?.data?.knowledge_item, record.contentParameter, false)
            }
            ).catch(error => {
                console.error(error);
            });
    };

    const functionMap = {
        'contentOptions': getContentOptions,
        'submitKnowledge': callSubmitKnowledge,
        'getKnowledge': callGetKnowledge,
        // Additional mappings can be added here
    };

    const TypingIndicator = () => {
        return (
            <div className="typing-indicator-message-bubble">
                <div className="typing-indicator">
                    <div className="typing-indicator-dot"></div>
                    <div className="typing-indicator-dot"></div>
                    <div className="typing-indicator-dot"></div>
                </div>
            </div>
        );
    };

    const MessageBubble = ({ message }) => {
        return (
            <div className={`cq-message-bubble`}>

                {message.content && message.content.map((msg, idx) => (
                    <div className={message.user} key={idx}>{msg[message.contentParameter]}</div>
                ))}
            </div>
        );
    };

    const UserInterface = ({ interfaceData }) => {
        const [customMessage, setCustomMessage] = useState('');
        const handleInputChange = (event) => {
            setCustomMessage(event.target.value);
        }
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && !isProcessing) {
                handleCustomMessage(customMessage)
            }
        };
        return (
            <div className="cq-user-interface-container" disabled={isProcessing}>
                <div className='cq-user-input-container'>
                    <input type="text" placeholder="Ask a question" value={customMessage} onChange={handleInputChange} onKeyDown={handleKeyDown} />
                    <button className='cq-submit-button' disabled={isProcessing} onClick={() => handleCustomMessage(customMessage)} >Submit</button>

                </div>
                <button className='cq-submit-button' disabled={isProcessing} onClick={() => handleRefreshTopics()} >Fresh Start</button>
                {/* <button className='cq-submit-button cq-coming-soon' disabled>
                    Save (Coming Soon)
                </button> */}
            </div>
        );
    };
    let options = interfaceRecord?.contentType === 'selectors' ? interfaceRecord?.content : [];

    return (

        <div className="cq-main-container">
            <Modal
                isOpen={showModal}
                onClose={handleModalClose}
                message="Please sign in before beginning your quest."
                buttons={['Ok', 'No Thanks']}
            />
            <div className="cq-message-history-container" ref={chatScrollLocation}>
                {transcript && transcript.map((message, idx) => (
                    message && (<div key={idx} className={`cq-messages  ${message.user}`}>
                        {message.contentDisplay ? <MessageBubble key={idx} message={message} /> : null}
                    </div>)

                ))}


                {options && (
                    <div className="cq-auto-options">

                        {options.map((message, idx) => (
                            <div key={idx} className="cq-option" title={message[interfaceRecord.contentParameter]} onClick={() => onTopicSelect(message)}>
                                {message[interfaceRecord.contentParameter]}
                            </div>
                        ))}
                    </div>)}
                
                {isProcessing && (<TypingIndicator />)}
            </div>
            {<UserInterface interfaceData={interfaceRecord} />}
        </div>
    );
};

export default CuriosityQuest;
