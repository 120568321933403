import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const endpoint = apiUrl + '/funcanny';

// Abstract common logic for making the API call
const makeApiRequest = async (params) => {
    try {
        const response = await axios.get(endpoint, { params });
        return response;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
};


export const getProfile = async (token) => {
    const params = {
        'action': 'get_profile',
        'token': token
    };
    const response = await makeApiRequest(params);
    return response?.data;
    
};

