import React, { useState, useEffect } from 'react';
import { chatbotCreate, createConversationConfig, returnSampleConvoconfig, checkUserAuthentication } from './apis.js';
import { useNavigate, useLocation } from 'react-router-dom';
import './CreateConvo.css';

function CreateConvo() {
  const { state } = useLocation();
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState();
  const [isSubmitting, setIsSubmitting] =useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    chatbot1: state?.config?.chatbot1?.context.replace("; Respond in less than 50 words. Be less agreeable.", "") || '',
    chatbot1name: state?.config?.chatbot1?.name || 'Chatbot A',
    chatbot2: state?.config?.chatbot2?.context.replace("; Respond in less than 50 words. Be less agreeable.", "") || '',
    chatbot2name: state?.config?.chatbot2?.name || 'Chatbot B',
    topic: state?.config?.topic || '',
  });

  useEffect(() => {
    // Call the async function to check user authentication
    checkUserAuthentication(setUser);
  }, []);


  function validateForm(data) {
    let errors = {};

    if (!data.chatbot1name.trim()) errors.chatbot1name = 'Chatbot A Name is required';
    if (!data.chatbot1.trim()) errors.chatbot1 = 'Chatbot A Definition is required';
    if (!data.chatbot2name.trim()) errors.chatbot2name = 'Chatbot B Name is required';
    if (!data.chatbot2.trim()) errors.chatbot2 = 'Chatbot B Definition is required';
    if (!data.topic.trim()) errors.topic = 'First Message is required';

    return errors;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loadSample = async () => {
    setFormData(returnSampleConvoconfig())
  };

  const handleClear = (e) => {
    setFormData({
      chatbot1: '',
      chatbot1name: 'Chatbot A',
      chatbot2: '',
      chatbot2name: 'Chatbot B',
      topic: '',
    });
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }
    const user_id = user?.username ?? 'Guest';
    const token = user?.signInUserSession.accessToken.jwtToken ?? ''
    const chatty1 = await chatbotCreate(formData.chatbot1name, formData.chatbot1, user_id, token)
    const chatty2 = await chatbotCreate(formData.chatbot2name, formData.chatbot2, user_id, token)

    createConversationConfig(chatty1.data.chatbot.PK, chatty2.data.chatbot.PK, formData.topic, user_id, token)
      .then(conversationConfig => {
        // console.log('CreateConvo output/conversation input', { state: { config: conversationConfig.data.conversation_config }, from: '/conversation' })
        navigate(`/conversation`, { state: { config: conversationConfig.data.conversation_config }, from: '/conversation' })

      })
      .catch(error => {
        console.error(error)
        setIsSubmitting(false);
      });


  }

  return (
    <form onSubmit={handleSubmit} className="create-convo-form">

      <div className="card">
        <h2>Chatbot A</h2>
        <label>Name:</label>
        <input type="text" name="chatbot1name" maxLength='64' value={formData.chatbot1name} onChange={handleChange} />
        {errors.chatbot1name && <p className="error">{errors.chatbot1name}</p>}
        <label>Definition:</label>
        <textarea className='chatbotDefinition' maxLength='2048' type="text" name="chatbot1" value={formData.chatbot1} onChange={handleChange} />
        {errors.chatbot1 && <p className="error">{errors.chatbot1}</p>}
      </div>
      <div className="card">
        <h2>Chatbot B</h2>
        <label>Name:</label>
        <input type="text" name="chatbot2name" maxLength='64' value={formData.chatbot2name} onChange={handleChange} />
        {errors.chatbot2name && <p className="error">{errors.chatbot2name}</p>}
        <label>Definition:</label>
        <textarea className='chatbotDefinition' maxLength='2048' type="text" name="chatbot2" value={formData.chatbot2} onChange={handleChange} />
        {errors.chatbot2 && <p className="error">{errors.chatbot2}</p>}
      </div>
      <div className="card">
        <label>First Message (from Chatbot A to Chatbot B):</label>
        <textarea className='chatbotDefinition' maxLength='2048' type="text" name="topic" value={formData.topic} onChange={handleChange} />

        {errors.topic && <p className="error">{errors.topic}</p>}
        <div className="button-container-create-convo">
          <button className="button-class-create-convo" type="button" onClick={loadSample} disabled={isSubmitting}>Surprise me</button>
          <button className="button-class-create-convo" type="button" onClick={handleClear} disabled={isSubmitting}>Clear</button>
          <button className="button-class-create-convo" type="submit" disabled={isSubmitting}>{user ? ("Start") :
            (<>Start<span className="tooltip-text-create-convo">Sign In Required</span>&#128274;</>)}</button>
        </div>
      </div>

    </form>
  );
}

export default CreateConvo;
