import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;
const endpoint = apiUrl + '/courthouse';

// Abstract common logic for making the API call
const makeApiRequest = async (params) => {
    try {
        const response = await axios.get(endpoint, { params });
        return response;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
};



export const submitArgument = async (partyA, partyB, dispute, judge, token) => {
    const params = {
        'action': 'submit_case',
        'judge': judge,
        'party_a': partyA,
        'party_b': partyB,
        'dispute': dispute,
        'token': token
    };
    const response = await makeApiRequest(params);
    return response?.data?.case
};

export const getCase = async (PK, SK, token, version) => {
    const params = {
        'action': 'get_case',
        'PK': PK,
        'SK': SK,
        'token': token,
        'version': version
    };
    const response = await makeApiRequest(params);
    return response?.data?.case
};

export const sendAudioTranscript = async (PK, SK, token) => {
    const params = {
        'action': 'send_case',
        'PK': PK,
        'SK': SK,
        'token': token
    };
    const response = await makeApiRequest(params);
    return response
};
