import React, { useState } from 'react';
import { submitFeedback } from '../base/apis';
import './Feedback.css';

const Feedback = ({ title, subtitle, user, context, stateHandler, complete }) => {
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const thankYou = "Thank you for sharing your feedback!";

    const handleSubmit = async (event) => {
        event.preventDefault();
        stateHandler('inprogress');
        submitFeedback(feedback, email ? 'email' : 'token', email || user.signInUserSession.accessToken.jwtToken, context);
        await new Promise(resolve => setTimeout(resolve, 3000));
        await stateHandler('complete');
        await new Promise(resolve => setTimeout(resolve, 10000));
        await stateHandler('empty');
    };

    return (
        <div className="fc-feedback-container">
            <h2 className="fc-feedback-title">{title}</h2>

            <form onSubmit={handleSubmit}>
                {!user && (
                    <div className="fc-feedback-input-group">
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email address"
                            required={!user}
                        />
                    </div>
                )}
                
                <p className="fc-feedback-subtitle">{subtitle}</p>
                <div className="fc-feedback-input-group">
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Type your thoughts, suggestions, or get support"
                        maxLength={500}
                        required
                    />
                </div>
                {complete ? (<div className="fc-feedback-thankyou-message">
                    {thankYou}
                </div>) :
                    <div className="fc-feedback-submit-button-group">
                        <button type="submit" className="fc-feedback-submit-button">Send Feedback</button>
                    </div>}
            </form>
        </div>

    );
};

export default Feedback;