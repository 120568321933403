
import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import surpriseConvos from './surprise.js';
// import ReactGA from 'react-ga4';


const apiUrl = process.env.REACT_APP_API_URL + '/api_handler';


export const chatbotCreate = async (name, context, user_id, token) => {
    context = context + "; Respond in less than 50 words. Be less agreeable." // #NOTE UPDATE CreateConvo context replace loading form data 
    try {
        const chatbot = await axios.get(apiUrl, {
            params:
            {
                'action': 'create_chatbot',
                'name': name,
                'context': context,
                'user_id': user_id,
                'token': token
            }
        });
        return chatbot;
    } catch (error) {
        try {
            const chatbot = await axios.get(apiUrl, {
                params:
                {
                    'action': 'create_chatbot',
                    'name': name,
                    'context': context,
                    'user_id': user_id,
                    'token': token
                }
            });
            return chatbot;
        } catch (error) {
            console.error('API call failed:', error);
            return null;
        }
    }
}
export const getConversation = async (config_conversation_pk, config_conversation_sk, user_id, token) => {
    try {
        const conversation = await axios.get(apiUrl, {
            params:
            {
                'action': 'get_conversation',
                'conversation_messages_id': `${config_conversation_pk}|${config_conversation_sk}`,
                'user_id': user_id,
                'token': token
            }
        });
        const messages = conversation.data.conversation_messages.sort((a, b) => a.message_count - b.message_count);
        const response = {'messages':messages, 'config': conversation.data.conversation_config}
        return response;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}
export const createConversationConfig = async (chatbot1, chatbot2, topic, user_id, token) => {
    try {
        const conversation_config = await axios.get(apiUrl, {
            params:
            {
                'action': 'create_conversation_config',
                'chatbot1': chatbot1,
                'chatbot2': chatbot2,
                'topic': topic,
                'user_id': user_id,
                'token': token,
                'message_limit': 6
            }
        });
        return conversation_config;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}

export const extendConversationConfig = async (conversation_config_pk, conversation_config_sk, token) => {
    try {
        const conversation_config = await axios.get(apiUrl, {
            params:
            {
                'action': 'extend_conversation',
                'PK': conversation_config_pk,
                'SK': conversation_config_sk,
                'extend_by': 4,
                'token': token
            }
        });
        return conversation_config;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}

export const deleteConversationMessage = async (conversation_pk, conversation_sk, token) => {
    try {
        const conversation_config = await axios.get(apiUrl, {
            params:
            {
                'action': 'delete_conversation',
                'PK': conversation_pk,
                'SK': conversation_sk,
                'token': token
            }
        });
        return conversation_config;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}

export const callCreateBotThenConfig = async (name1, context1, name2, context2, topic, user_id, token) => {
    try {
        const chatbot1 = await chatbotCreate(name1, context1, user_id, token);
        const chatbot2 = await chatbotCreate(name2, context2, user_id, token);
        
        try {
            const conversation_config = await createConversationConfig(chatbot1, chatbot2, topic, user_id, token);
            return conversation_config;
        } catch (error) {        
            console.error('createConversationConfig call failed:', error);
            return null;
        } 
    } catch (error) {        
        console.error('chatbotCreate call failed:', error);
        return null;
    }
};
export const callSendAudio = async (pk, sk, token) => {
    try {
        const conversation_config = await axios.get(apiUrl, {
            params:
            {
                'action': 'create_audio_conversation',
                'PK': pk,
                'SK': sk,
                'token': token
            }
        });
        return conversation_config;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
};
export const createPaymentIntent = async (token, credits) => {
    const paymentIntent = await axios.get(apiUrl, {
        params:
        {   'action': 'create_payment_intent',
            'token': token,
            'price_id': 'price_1OEcn5FbJqYLssgp7zuycJFe',
            'credits': credits,
        }
    });
    return paymentIntent
}

export const returnSampleConvoconfig =  () => {

    const randomIndex = Math.floor(Math.random() * surpriseConvos.length);
    return surpriseConvos[randomIndex];
};

export const signupEmail = async (email) => {
    try {
         await axios.get(apiUrl, {
            params:
            {
                'action': 'signupemail',
                'email': email
            }
        });
        console.log('success')
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}


export const submitFeedback = async (feedback, userInfoType, userInfo, context) => {
    try {
        const response = await axios.get(apiUrl, {
            params:
            {
                'action': 'submit_feedback',
                'feedback': feedback,
                'user_info_type': userInfoType,
                'user_info': userInfo,
                'context': context
            }
        });

        return response;
    } catch (error) {
        console.error('API call failed:', error);
        return null;
    }
}


export const checkUserAuthentication = async (setUser) => {
    try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUser(userInfo);
    } catch (error) {
        console.error("Error fetching user: ", error);
    }
}
// export const test_session = async () => {
//     const credentials = await Auth.currentCredentials();
    
// }

// export const sendEventLog = async (event, event_type, event_value, user_id) => {
    
//     // const event_datetime = new Date().toISOString();
//     try {
//         const response = axios.get(apiUrl, {
//             params: {
//                 'action': 'event_analytics',
//                 'event': event,
//                 'event_type': event_type,
//                 'event_value': event_value,
//                 // 'event_datetime': event_datetime,
//                 'user_id': user_id,
//                 'version': '1_0'
//             }
//         });

//     } catch (error) {
//         console.error("Error sending event log:", error);
//     }
// }




// export const logButtonClick = async (page) => {
//     try {

//     const test = ReactGA.event({
//       category: 'Button',
//       action: 'Click',
//       label: page,
//       value: 1});
      
//     } catch (error) {
//         console.error("Error sending ga event:", error);
//     }
// };


