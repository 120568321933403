import React from 'react';

const Terms = () => {
    return (
        <div style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>
            <h1 style={{ color: 'black', fontFamily: 'Arial' }}>Terms of Service (Funcanny AI)</h1>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}><strong>Effective Date:</strong> 7/25/2023</p>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Welcome to Funcanny AI! I present to you the Terms of Service that govern your use of our application. Please carefully read and understand these terms before proceeding.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>1. Agreement to Terms</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>By accessing or using Funcanny AI, you acknowledge that you have read, understood, and agreed to be bound by these Terms of Service. If you do not agree with any part of these terms, please refrain from using the app.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>2. User Eligibility</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>To use our app, you must be at least 18 years old. If you are under 18, you are prohibited from using the app, and we kindly ask you to cease all usage immediately.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>3. User Responsibilities and Conduct</h2>
            <ul>
                <li>You are solely responsible for the content and messages you send using our app.</li>
                <li>You must not use the app for any unlawful, harmful, or offensive activities.</li>
                <li>Respect the privacy and rights of other users. Harassment, bullying, or any harmful conduct towards others will not be tolerated.</li>
                <li>Unsolicited messages and spamming are strictly prohibited.</li>
                <li>Impersonation or misrepresentation of identity is not allowed.</li>
            </ul>
            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>4. Nature of Service</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>The apps on Funcanny AI are for entertainment, or informational purposes only. The AI courthouse is not a substitute for professional legal advice or services and does not provide binding legal judgments. Use of the AI judge does not constitute legal counsel.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>5. AI Limitations and Accuracy</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>The AI provided on this platform may not be infallible and could produce errors, inaccuracies, or "hallucinations." Users should exercise caution and not rely solely on the AI's output for making decisions. The service is not responsible for any actions taken based on the AI's information.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>4. Privacy and Data Usage</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>At Funcanny AI, we take your privacy seriously. Our practices regarding the collection, use, and protection of your personal information are detailed in our <a href="/privacy-policy">Privacy Policy</a>.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>5. Intellectual Property Rights</h2>
            <ul>
                <li>All intellectual property rights related to our app and its content are owned or licensed by us. Any unauthorized use, reproduction, or distribution of our app or content is strictly prohibited.</li>
                <li>By using our app, you grant us a non-exclusive license to use, reproduce, and display the content you send through the app for the sole purpose of providing our services.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>6. Service Availability and Interruptions</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>While we strive to maintain a continuous and reliable service, we cannot guarantee uninterrupted access to our app. We may need to suspend or terminate services for maintenance or other reasons without prior notice.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>7. Third-Party Links and Services</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Our app may contain links to third-party websites or services for your convenience. We do not endorse or take responsibility for the content or practices of these third-party sites.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>8. Limitation of Liability</h2>
            <ul>
                <li>Under no circumstances shall Funcanny AI or its representatives be liable for any direct, indirect, incidental, or consequential damages arising from your use of the app.</li>
                <li>We are not responsible for the content or conduct of our users.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>9. Amendments to the Terms</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>We reserve the right to update or modify these Terms of Service at any time. Any changes will be effective immediately upon posting. Please review the terms periodically for updates.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>10. Termination</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>We reserve the right to suspend or terminate your access to the app if you violate these terms or for any other lawful reason without prior notice.</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>11. Payment Processing</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>We use a third party service (like Stripe) for processing payments. We do not store or process your card details ourselves; they are processed and stored by Stripe. Our platform ensures secure transmission of your card details</p>

            <h2 style={{ color: 'black', fontFamily: 'Arial' }}>12. Governing Law and Jurisdiction</h2>
            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>These Terms of Service shall be governed by and construed in accordance with the laws of California in the United States. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of California in the United States.</p>

            <p style={{ color: 'black', fontSize: '16px', fontFamily: 'Arial' }}>Thank you using Funcanny AI. If you have any legal questions or concerns, please do not hesitate to contact us at <a href="mailto:hello@funcannyai.com">hello@funcannyai.com</a>.</p>

        </div>
    );
};

export default Terms;
