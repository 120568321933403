import React from 'react';
import ProfileDropdown from './ProfileDropdown';
import './Base.css';
import './Header.css';

const Header = () => {


    return (
        <header className="header">
            <div className="header-content">
                <a href="/" className="nav"><div className="logo">
                </div>   </a>
                <div className="header-domain">funcannyai.com</div>
                <nav className="nav">
                    {/* <ul className="nav-list">
        </ul> */}
                    <ProfileDropdown />
                </nav>
            </div>
        </header>
    );
};

export default Header;