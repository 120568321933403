import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePayment from './StripePayment';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createPaymentIntent, checkUserAuthentication } from './apis';
import './Pricing.css';

const stripePublicKey = process.env.REACT_APP_STRIPE_PK || 'pk_test_51NBm5sFbJqYLssgpismK03VWuTleMod8Vl3qQsJ0NQEmz7W7ObB9BTHfKxUWy6xece56XbjhWwmYj9ORuMNeP3kg00q1rPt85A';

const StripeParent = () => {
    const [user, setUser] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [credits, setCredits] = useState(1);
    const [options, setOptions] = useState({
        clientSecret: '',
    });
    const navigate = useNavigate();
    const location = useLocation();
    const price = 1.99;
    const new_price = 0.99;
    const queryParams = new URLSearchParams(location.search);
    const app = queryParams.get('appname') || 'general';
    const handleEdit = (e) => {
        setShowPayment(false);
        setOptions({
            clientSecret: '',
        });
    };
    const handleCreditChange = (e) => {
        setCredits(e.target.value)
    };
    const appName = { 'courthouse': 'AI Courthouse', 'aivsai': 'AI vs AI', 'curiosityquest': 'Curiosity Quest' }

    useEffect(() => {
        checkUserAuthentication(setUser)

    }, []);

    const stripePromise = loadStripe(stripePublicKey);
    const handlePayment = async (event) => {
        if (user) {
            setShowPayment(true)
            const { data } = await createPaymentIntent(user.signInUserSession.accessToken.jwtToken, credits);
            setOptions({

                clientSecret: data['pis'],
            });
        } else {
            location.pathname=`${location.pathname}?appname=${app}`
            console.log('path', location)
            navigate('/signin', { state: location });
            

        }

    };
    const NavigateToApp = (path) => {
        navigate(path)
    }
    return (
        <div className="pricing-container" >
            {['curiosityquest'].includes(app) && (
                <div className="pricing-section">
                    <h1 className="pricing-heading">{appName[app]} Pricing</h1>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">FREE</div>
                            <p>Love the {appName[app]} app? 🌟 </p>
                            <p>Show your support with a coffee! ☕ </p>
                        </div>

                    </div><a href="https://www.buymeacoffee.com/saneilsuri" target="_blank" rel="noopener noreferrer">
                        <img className="bmc-container" src="icons_logos/bmc.png" alt="BuyMeACoffee"></img>
                    </a>
                </div>)}
            {app === 'aivsai' && (
                <div className="pricing-section">
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">{appName[app]}</div>
                            <p>Text only conversations are Free</p>
                            <p>Audio conversation = 1 credit</p>

                        </div>

                    </div>
                    {/* <h1 className="pricing-heading">{appName[app]} Pricing</h1>


                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <p>Text Conversation creation is free</p>
                            <p>Converting to Audio = 1 credit</p>

                            <p>Pay only for what you use </p>

                        </div>

                    </div> */}
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">Limited Time Offer</div>
                            <p>First 2 Funcanny AI Credits are Free!</p>
                            <p>Then <label className="crossed-out" >${price}</label>${new_price}/ additional credit *</p>
                            <p>*50% off credits for limited time!</p>
                            <p>Credits apply to all Funcanny AI Apps</p>
                        </div>
                    </div>
                    <div className="pricing-form" >
                        <div className="pricing-dropdown-container">
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Purchase Credits</label>
                            <select
                                id="credit-selection"
                                className="pricing-dropdown-select"
                                value={credits}
                                onChange={handleCreditChange}
                                disabled={showPayment}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label crossed-out">Total:
                                <span className="pricing-total ">{credits} x ${price} = ${credits * price}</span>
                            </label>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Total:

                                <span className="pricing-total">{credits} x ${new_price} = ${(credits * new_price).toFixed(2)}</span>
                            </label>

                            {!showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handlePayment}
                                >
                                    Purchase
                                </button>

                            </div>
                            )}
                            {showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>

                            </div>
                            )}
                        </div>
                    </div>
                </div>)}
            {app === 'courthouse' && (
                <div className="pricing-section">



                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">{appName[app]}</div>
                            <p>1 Case = 1 Funcanny AI Credit</p>
                            <p>Case includes text and audio</p>
                        </div>

                    </div>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">Limited Time Offer</div>
                            <p>First 2 Funcanny AI Credits are Free!</p>
                            <p>Then <label className="crossed-out" >${price}</label>${new_price}/ additional credit *</p>
                            <p>*50% off credits for limited time!</p>
                            <p>Credits apply to all Funcanny AI Apps</p>
                        </div>
                    </div>
                    <div className="pricing-form" >
                        <div className="pricing-dropdown-container">
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Purchase Credits</label>
                            <select
                                id="credit-selection"
                                className="pricing-dropdown-select"
                                value={credits}
                                onChange={handleCreditChange}
                                disabled={showPayment}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label crossed-out">Total:
                                <span className="pricing-total ">{credits} x ${price} = ${credits * price}</span>
                            </label>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Total:

                                <span className="pricing-total">{credits} x ${new_price} = ${(credits * new_price).toFixed(2)}</span>
                            </label>

                            {!showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handlePayment}
                                >
                                    Purchase
                                </button>

                            </div>
                            )}
                            {showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>

                            </div>
                            )}
                        </div>
                    </div>
                </div>)}
            {/* {app == 'courthouse' && (
                <div className="pricing-section">
                    <h1 className="pricing-heading">{appName[app]} Pricing</h1>

                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">Limited-Time Offer: 50% Off!</div>
                        </div>
                    </div>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <p>The first 3 case credits are FREE!</p>
                            <button className="pricing-card pricing-tryit" onClick={(event) => NavigateToApp(`/courthouse`)} >Go try it!</button>
                            <p>Then purchase additional case credits.</p>
                            <div className="pricing-card-title"><div className="crossed-out" >${price}  per case</div>${new_price} per case</div>
                            <p>Pay only for what you use</p>

                        </div>

                    </div>
                    <div className="pricing-form" >
                        <div className="pricing-dropdown-container">
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Purchase Case Credits</label>
                            <select
                                id="credit-selection"
                                className="pricing-dropdown-select"
                                value={credits}
                                onChange={handleCreditChange}
                                disabled={showPayment}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label crossed-out">Total:
                                <span className="pricing-total ">{credits} x ${price} = ${credits * price}</span>
                            </label>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Total:

                                <span className="pricing-total">{credits} x ${new_price} = ${(credits * new_price).toFixed(2)}</span>
                            </label>

                            {!showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handlePayment}
                                >
                                    Purchase
                                </button>

                            </div>
                            )}
                            {showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>

                            </div>
                            )}
                        </div>
                    </div>
                </div>)} */}

            {app === 'general' && (
                <div className="pricing-section">
                    <h1 className="pricing-heading">Credits Purchase</h1>

                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <div className="pricing-card-title">Limited-Time Offer: 50% Off!</div>
                        </div>
                    </div>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <p>Credits can be used for any Funcanny AI App.</p>
                            <p>The first 3 credits are FREE! </p>
                            <p>Then purchase additional case credits.</p>
                            <div className="pricing-card-title"><div className="crossed-out" >${price}  per credit</div>${new_price} per credit</div>
                            <p>Pay only for what you use</p>
                        </div>

                    </div>
                    <div className="pricing-form" >
                        <div className="pricing-dropdown-container">
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Purchase Case Credits</label>
                            <select
                                id="credit-selection"
                                className="pricing-dropdown-select"
                                value={credits}
                                onChange={handleCreditChange}
                                disabled={showPayment}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label crossed-out">Total:
                                <span className="pricing-total ">{credits} x ${price} = ${credits * price}</span>
                            </label>
                            <label htmlFor="credit-selection" className="pricing-dropdown-label">Total:

                                <span className="pricing-total">{credits} x ${new_price} = ${(credits * new_price).toFixed(2)}</span>
                            </label>

                            {!showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handlePayment}
                                >
                                    Purchase
                                </button>

                            </div>
                            )}
                            {showPayment && (<div>
                                <button
                                    type="submit"
                                    className="pricing-submit-button"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>

                            </div>
                            )}
                        </div>
                    </div>
                </div>)}



            {showPayment ?
                (

                    !options.clientSecret ? (<div className="pricing-cards"><div><div className="loader"></div>Loading</div></div>) : (
                        <div className="pricing-cards">
                            <Elements stripe={stripePromise} options={options} >

                                <StripePayment credits={credits} price={new_price} editButton={handleEdit} />

                            </Elements> </div>)) : (app !== 'general' && (<div className="pricing-cards">
                                <button className="pricing-card pricing-tryit" onClick={(event) => NavigateToApp(`/${app}`)} >Try {appName[app]}</button>
                            </div>))}

        </div>
    );
};

export default StripeParent;