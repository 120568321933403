const exampleCases = [

    {   "next_example_no": "2",
        "judge": "neutral",
        "dispute": "We are a couple who are amicably separating and are pretty chill about splitting up our stuff, except for our Dog Max. We both want sole custody of Max, who we adopted together 3 years ago. Party A has always had a special connection with Max, being the one who initially wanted to adopt him. Party B, though initially reluctant and self-described as \"never a dog person,\" has grown to love Max deeply. Party A is set on moving to the other side of the country and may be traveling often. Party B thinks Max will be better off staying put since they're always home and can give him a regular routine.",
        "partyA": "I've loved Max since the day we decided to adopt him as I've been his primary caregiver and he would be devastated without me. Even though I'm moving far away, I can't bear the thought of leaving him. I'm willing to make any arrangement necessary to keep him with me, despite the distance and my travel.",
        "partyB": "Initially, I wasn't even a dog person, but Max changed all that. Now that Party A is moving across the country, I believe Max needs the stability and routine that I can offer. I want Max to stay with me where his life won't be turned upside down.",
        "validation": "Party A, I sense your deep emotional bond with Max and understand that your desire to keep him with you stems from your integral role in his life since adoption. I acknowledge your willingness to make suitable arrangements for Max's care, despite the personal challenges of a long-distance move and frequent travel." +
            "\n\nParty B, your late-blooming attachment to Max is no less poignant and valuable. A pet, indeed, often changes life in unexpected ways. I appreciate your concern for maintaining consistency and routine for Max, especially in light of the potential upheaval caused by Party A's relocation. Your readiness to compromise for Max's welfare speaks to your affection for him.",
        "ruling": "Headline: Judge rules in favor of Party B." +
            "\n\nAnalysis: Both parties have an undeniable attachment to Max. However, considering the well-being of the pet is paramount. Party A, despite their emotional bond with Max, anticipates a lifestyle that may not provide the stability a pet often needs. While they have expressed willingness to find a solution, predictable routines, especially during a split, provide comfort to animals. Party B can provide this consistently, which tips the scales in their favor. " +
            "\n\nConclusion: The court acknowledges Party A's deep affection for Max but notes that love is about making decisions for the good of the loved one. In this circumstance, it appears that Max would experience less upheaval and have a stable routine with Party B. Hence, the court rules in favor of Party B for Max's custody. It would be in the best interest of Max to remain in a stable and consistent environment during this period of change.",
        "factsIssueRules": "Facts: The couple, Party A and Party B, is separating. Among their shared assets is their cherished dog, Max, adopted 3 years ago. Party A, despite plans to move across the country and frequent travel, strongly wishes to retain Max's custody. Party B, who prefers stability and routine for Max, advocates for Max's retention with them. " +
            "\n\nIssue: The core issue is to whom the custody of Max should be awarded, keeping in view the welfare and happiness of the pet." +
            "\n\nRules: The primary rule governing this dispute is determining what arrangement is in Max's best interest. This includes considering factors such as the capability to provide consistent care, Max's relationship with both parties, lifestyle changes, and each party's willingness and ability to accommodate Max's needs.",
        "presignedUrl":"https://static-funcannyai.s3.us-west-1.amazonaws.com/audio_courthouse/ai_courthouse_ruling_example1.mp3"
        },
    {   "next_example_no": "3",
        "judge": "neutral",
        "dispute": "Friends dispute over one frequently canceling plans due to work, leaving the other feeling undervalued and questioning the commitment to their friendship.",
        "partyA": "I'm disappointed and feel like you don't prioritize our friendship. Your frequent cancellations, like bailing on our trip, make me feel undervalued. It seems like work always comes first, and I'm left adjusting my plans.",
        "partyB": "I'm sorry for the cancellations, but my job is really demanding. It's not that I don't value our friendship, but sometimes work has to take precedence. I'm trying to balance everything, but it's challenging. I wish you could be more understanding of my situation.",
        "validation": "Party A, I understand your feelings of disappointment and hurt. It's clear that you greatly value this friendship and feel that it's not being prioritized. Having your expectations frequently unmet due to cancellations is understandably challenging." +
            "\n\nParty B, I recognize the demands of your job and how they often necessitate rearranging your personal commitments. It seems you're caught in an ongoing struggle to balance work and personal life, and you're seeking understanding and flexibility from your friend.",
        "ruling": "Judge rules in favor of Party A." +
            "\n\nUpon analysis, while it's understandable that Party B's job demands might interrupt plans, the frequent cancellations symbolize a pattern of behavior that disregards Party A's feelings and commitment to the friendship. A strong friendship is built on mutual respect, understanding, and flexibility. " +
            "\n\nIn conclusion, Party B is encouraged to become more mindful of their work-life balance. Everyone has their own cross to bear, and it's important to understand when and how to draw boundaries. Party B should consider setting aside reserved time for personal relationships outside of work, or else they risk causing further damage to their friendship with Party A. Remember, a well-balanced life leads to healthier relationships.",
        "factsIssueRules": "The facts in this case are that Party A and Party B are friends who had plans to spend time together, but those plans have frequently been cancelled by Party B due to their work commitments. This has led to Party A feeling undervalued and questioning the friendship, while Party B feels challenged by their demanding job and wishes for more understanding from Party A." +
            "\n\nThe issue then stems from a lack of mutual understanding and empathy in this friendship. The discrepancy between expectations, the reality of their commitments, and the manifestation of those commitments are causing tension between the two parties." +
            "\n\nAs for the rules governing this dispute, it should ideally be managed by mutual understanding, respect, and communication. Both parties should be able to express their feelings and anxieties about the relationship, and both should be willing to listen to each other’s point of view. In friendship, balance is often necessary where both parties feel heard and their feelings and situations are respected.",
            "presignedUrl":"https://static-funcannyai.s3.us-west-1.amazonaws.com/audio_courthouse/ai_courthouse_ruling_example2.mp3"
    },
    {   "next_example_no": "4",
        "judge": 'neutral',
        "dispute": "My business partner and I started a company together and agreed upon certain equity Terms. I would like to renegotiate the terms to reflect our efforts and contributions.",
        "partyA": "I feel the current equity distribution in our startup doesn't reflect the amount of work and effort I've been putting in. My contributions have been significant, and I believe a reassessment is necessary for fairness. If we're planning for the future of the company, I need to feel valued and fairly compensated. This isn't about betrayal; it's about acknowledging the imbalance and rectifying it for the good of the company and my personal commitment.",
        "partyB": "The equity split was agreed upon at the start, and changing it now could jeopardize the stability of our startup. We all have different roles and have made sacrifices. I see this move as a betrayal of the trust and agreement we established when we embarked on this journey. It's important to consider the future direction of the company and not make decisions that could undermine our partnership and the business we've built together.",
        "validation": "I understand, Party A, that you are seeking acknowledgment for your efforts and your contributions to the partnership. You feel that the current equity distribution isn't matching the work you're doing and you're calling for a recalibration to feel valued and fairly compensated." +
            "\n\nTo Party B, I acknowledge your concerns that changing the terms of an already agreed equity split may disrupt the stability of your startup. You believe it's important to uphold initial agreements and avoid decisions that might undermine the partnership and the progress you've both made.",
        "ruling": "Headline: Judge rules in favor of Party B." +
            "\n\nAnalysis: In light of the contract law and the original terms of the partnership, the existing equity distribution stands. Although Party A's concerns about the disparity in effort are acknowledged, their claim lacks objective metrics to accurately measure the disparity. Starting a business requires various forms of contributions, which may not always be quantifiable or directly comparable." +
            "\n\nConclusion: Given the established agreement and the principles of contract law, the request to change equity distribution terms is denied at this time. However, it might be valuable for both parties to adopt a potential reward system or bonus structure that acknowledges extraordinary efforts or sacrifices made on behalf of the company, providing a solution that enhances engagement and preserves the stability of the company.",
        "factsIssueRules": "The fact of this dispute resides in the equity distribution of a startup co-founded by Party A and Party B. Party A asserts that their contributions entitle them to a larger share, implying their work isn’t proportionate to their present equity." +
            "\n\nThe issue here revolves around whether the initially agreed equity distribution can be modified to reflect the perceived discrepancy in contributions and if such modification is deemed fair and necessary for the continuity of the partnership and the business itself." +
            "\n\nhe governing rule in such disputes relies on the principles of contract law, partnership agreements, and good faith. They emphasize clear communication, adherence to initial agreements, and changes only being implemented when consent is obtained from both parties.",
            "presignedUrl":"https://static-funcannyai.s3.us-west-1.amazonaws.com/audio_courthouse/ai_courthouse_ruling_example3.mp3"
    },


    {   "next_example_no": "1",
        "judge": "neutral",
        "dispute": "Roommates argue over borrowed sunglasses that accidentally got damaged.",
        "partyA": "I borrowed the sunglasses with Party B's permission, and unfortunately they got scratched up because she didn't give me the special case they came in.  She barely used them anyway.",
        "partyB": "Party A borrowed my limited addition sunglasses, and he returned them all scratched up. He was careless with them.  ",
        "validation": "Party A, I understand that you borrowed the sunglasses from Party B and unfortunately, they ended up scratched. You argue this was due to the lack of a special case. You also seem to feel that the damage is somewhat mitigated by Party B's infrequent use of the sunglasses\n\nParty B, I acknowledge your frustration that your limited edition sunglasses were returned damaged. You trusted Party A with your property, and it's clear you feel that trust was breached due to perceived carelessness. Your concern is understandable given the sunglasses' unique status.",
        "ruling": "Headline: Judge rules in favor of Party B. \n \n Analysis: While Party A borrowed the sunglasses, it was their responsibility to take suitable care of them - which includes taking necessary steps to protect them, even if they weren't explicitly provided with the specific case for the sunglasses. It's not deemed reasonable to assume Party B's infrequent usage of the sunglasses lessens Party A's duty of care over the borrowed item. \n\nConclusion: Although I can sympathize with Party A's situation, the principle of care needed when borrowing another's belongings must be upheld. With these considerations, I rule in favor of Party B. Party A had a responsibility to ensure the glasses’ safekeeping and failed to do so. This teaches us the importance of clarifying expectations upfront when borrowing or lending personal belongings, to avoid similar situations in the future.",
        "factsIssueRules": "Facts: The factual situation seems to be that Party A borrowed sunglasses from Party B, and the sunglasses were returned with scratches. It's not disputed that Party B didn't provide a special case, and Party A suggests this may have caused the damage. \n\nIssue: The issue at stake is determining who is responsible for the sunglasses' damage. This is a disagreement regarding the understanding and the terms under which the sunglasses were borrowed.\n\nRules: In general, a borrowing party must take reasonable care of borrowed items, returning them in the same condition they were lent. If damage results from normal usage or unforeseen accident, determining responsibility can be more complex. The owner's duty to provide necessary protective measures, like the special case, could also influence the decision.",
        "presignedUrl":"https://static-funcannyai.s3.us-west-1.amazonaws.com/audio_courthouse/ai_courthouse_ruling_example4.mp3"

    },
    // {   "next_example_no": "1",
    //      "judge": "",
    //     "dispute": "",
    //     "partyA": "",
    //     "partyB": "",
    //     "validation": "",
    //     "ruling": "",
    //     "factsIssueRules": "",
    //      "presignedUrl":""
    // }
]


export const judgeOptions = [
    { value: 'Neutral', description: 'A neutral, fair judge', internal: 'neutral' },
    { value: 'Empathethic', description: 'An empathethic, compassionate judge', internal: 'empathetic' },
    { value: 'Stern', description: 'A stern, no-nonsense judge', internal: 'stern' },
    { value: 'Eccentric', description: 'An eccentric and open-minded judge', internal: 'eccentric' },
    { value: 'Mediator', description: 'A mediator focused on compromise', internal: 'mediator' },
    // Add more options here
];

function createMessageIterator(cases) {
    let index = 0;
    return function () {
        if (index >= cases.length) {
            index = 0;
        }
        return cases[index++];
    }
}

export const getSampleCase = createMessageIterator(exampleCases);


