import React, { useState, useEffect } from 'react';
import { checkUserAuthentication } from '../base/apis';
import './Landing.css';
import EmailList from './EmailList';
import Feedback from '../elements/Feedback';
import MiniAppCarousel from './MiniApps';
// import { sendEventLog } from './apis';

const Landing = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
        const section = document.querySelector(hash);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
}, []);

  const [feedbackState, setFeedbackState] = useState();

  const [user, setUser] = useState();
  const handleFeedbackProgress = async (state) => {
    setFeedbackState('complete')
  }
  const miniApps = [
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/courthouse-poly.png",
      name: "AI Courthouse",
      description: "Submit your dispute before an AI Judge.",
      path: "/courthouse",
      pricing: 'courthouse'

    },
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/conversations-poly.png",
      name: "AI vs AI",
      description: "Create interesting conversations between two distinct AIs",
      path: "/aivsai",
      pricing: 'aivsai'
    },
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/curiosity-poly.png",
      name: "Curiosity Quest AI",
      description: "Like going down a wiki rabbit hole, with an AI guide.",
      path: "/curiosityquest",
      pricing: 'curiosityquest'
    }
  ];

  const comingSoonApps = [
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/comingsoon-poly.png",
      name: "AI Resume Helper",
      description: "Upload your resume, then use it to help with applying to jobs.",
      path: "?app=resume"
    },
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/comingsoon-poly.png",
      name: "Ask a Gaggle",
      description: "Keeping this one under wraps until it is live. Shhh...",
      path: "?app=gaggle"
    },
    {
      imageUrl: "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/comingsoon-poly.png", // Replace with actual image URL
      name: "Model Compare",
      description: "Compare responses from multiple LLMs at once.",
      path: "?app=compare"
    }
  ];
  useEffect(() => {
    checkUserAuthentication(setUser);
  }, []);

  return (

    <div>
      <div className="landing-container">

        <section id="liveapps" className="section-live-apps">
          <div className='carousel-container'>
            <h2>Try a unique AI mini webapp</h2>
            <MiniAppCarousel miniApps={miniApps} />
          </div>
        </section>
        <section id="comingsoon" className="section-comingsoon-apps">
          <div className='carousel-container'>
            <h2>And More Apps Coming Soon...</h2>
            <MiniAppCarousel miniApps={comingSoonApps} />
            <EmailList />
          </div>
        </section>


        <div className='carousel-container landing-feedback'>
          <section id="feedback" className="section-feedback-apps">
            <h2>Feedback and Questions</h2>
            <Feedback title=''
              subtitle='Got feedback? Your insights drive this site!'
              user={user}
              context="general"
              complete={feedbackState === 'complete'}
              stateHandler={handleFeedbackProgress} />
          </section>
          
        </div>
        <div className='carousel-container landing-feedback'>
        <section id="toolpilot" className="section-live-apps">
          <a href="https://www.toolpilot.ai/products/funcanny-ai" target="_blank"><img src="https://www.toolpilot.ai/cdn/shop/files/toolpilot-badge-w.png" alt="Funcanny AI Is Featured On ToolPilot.ai"/></a>
          </section>
          </div>
      </div>
  
    </div>



  );
}

export default Landing;