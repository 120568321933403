import React, {useEffect}  from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import Landing from './base/Landing';
import Header from './base/Header';
import Footer from './base/Footer';
import CustomSignIn from './base/CustomSignIn';
import CreateConvo from './base/CreateConvo';
import Conversation from './base/Conversation';
import EmailList from './base/EmailList';
import ProtectedRoute from './base/ProtectedRoute';
import PrivacyPolicy from './base/PrivacyPolicy';
import Terms from './base/Terms';
import StripeParent from './base/StripeParent';
import ErrorBoundary from './base/ErrorBoundary';
import ReactGA from 'react-ga4';
import CuriosityQuest from './curiosityQuest/CuriosityQuest';
import Courthouse from './courthouse/Courthouse';
import UserProfile from './base/UserProfile';


Amplify.configure({
  Auth: {
      region: process.env.REACT_APP_REGION, 
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
  }
});

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? 'G-00S8TD7WHN'); 

const data = {
  title: 'Space Exploration',
  description: 'This is the root node',
  content: 'very long content .....',
  children: [
    {
      title: 'Mars Colonization',
      description: 'The idea of humans living on Mars isnt just a sci-fi dream anymore. Scientists are researching the feasibility of life on Mars, focusing on its atmosphere, climate, and potential resources.',
      content: 'very long content .....',
      children: [
      ]
    },
    {
      title: 'Dark Matter and Dark Energy',
      description: 'These mysterious entities make up approximately 95% of the universe, yet we know almost nothing about them. Researchers believe these might hold the answers to the universes expansion and structure.',
      content: 'very long content .....',
      children: []
    },
    {
      title: 'Astrobiology',
      description: 'Its the study of the origin, evolution, and future of life in the universe. The main focus is the search for life beyond Earth, specifically extraterrestrial life.',
      content: 'very long content .....',
      children: []
    }
  ]
};


const ChildComponent = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", 
                    page: window.location.pathname + window.location.search, 
                    title: location.pathname });
  }, [location]);

  return <div></div>;
};


const App = () => {
  return (
    <div>
      <Router>
      {/* <Header /> */}
      <Header />
      <main>
      <ChildComponent />
      <Routes>
        <Route path="/" element={<Landing/>} />
        <Route path="/home" element={<Landing/>} />
        <Route path="/aivsai" element={<CreateConvo/>} />
        <Route path="/conversation" element={<ProtectedRoute  element={<Conversation passthrough="/conversation"/>} />} />
        <Route path="/signupemail" element={<EmailList/>} />
        <Route path="/error-page" element={<ErrorBoundary/>} />
        <Route path="/pricing" element={<StripeParent/>} />
        <Route path="/signin" element={<CustomSignIn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<Terms />} />
        <Route path="/curiosityquest" element={<CuriosityQuest />} />
        <Route path="/courthouse" element={<Courthouse />} />  
        <Route path="/account" element={<ProtectedRoute  element={<UserProfile passthrough="/account"/>} />} />       
      </Routes>
      </main>
    </Router>
    <Footer />
    </div>
    
  );
};

export default App;