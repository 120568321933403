import React, { useState } from 'react';
import { signupEmail } from './apis.js';
import './EmailList.css';


function EmailList() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
            setEmail('');

       
        if (validateEmail(email)) {
            signupEmail(email).then(conversationConfig => {
                console.log('signupEmail success')
                })
                .catch(error => {
                    console.log(error)
              });
              setShowToast(true);
            setMessage('Thank you for signing up!');
            setTimeout(() => setShowToast(false), 3000);
        } else {
            setMessage('Please enter a valid email address.');
        }
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(email);
    };

    return (
        <div className="emailSignUp">
        <h2>Subscribe to learn when new apps or features go live! </h2>
        
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit" className="button-emaillist">Subscribe</button>
        </form>
       <div className="toast">{showToast ? 'Thank you for signing up!' : 'Note: less than 1 email/week' }</div>
       {/* <div className='subscription-text'>* I will not to send more than 1 email a week. </div> */}
      </div>
    );
}

export default EmailList;