import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { submitArgument, getCase, sendAudioTranscript } from './CourthouseApi';
import { checkUserAuthentication } from '../base/apis';
import { getSampleCase, judgeOptions } from './ExampleCases.js'
import { getProfile } from '../base/BaseApi';
import Modal from '../elements/Modal.js';
import Feedback from '../elements/Feedback';
import './Courthouse.css';


const Courthouse = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [dispute, setDispute] = useState(state?.prevState?.state?.dispute || '');
    const [partyA, setPartyA] = useState(state?.prevState?.state?.partyA || '');
    const [partyB, setPartyB] = useState(state?.prevState?.state?.partyB || '');
    const [showModal, setShowModal] = useState(false);
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const [caseValidation, setCaseValidation] = useState('');
    const [caseFactsIssueRules, setCaseFactsIssueRules] = useState('');
    const [caseRuling, setCaseRuling] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [credits, setCredits] = useState();
    const [user, setUser] = useState();
    const disclaimer = "DISCLAIMER: It's probably obvious, but our AI Judge is here for fun, not serious legal matters."
    const [activeTab, setActiveTab] = useState(0);
    const queryParams = new URLSearchParams(location.search);
    const setup_intent = queryParams.get('setup_intent');
    const [audioEmail, setAudioEmail] = useState('');
    const [caseData, setCaseData] = useState('');
    const [presignedUrl, setPresignedUrl] = useState('');
    const [exampleButton, setExampleButton] = useState('See Example');

    const lookupJudge = (judgeInternal) => {
        const option = judgeOptions.find(option => option.internal === judgeInternal);
        return option || judgeOptions[0];
    };
    const handleSendAudioTranscript = async () => {

            setSection('AudioTranscript', true, 'inprogress');
            setIsSubmitting(true);
            const result = await sendAudioTranscript(caseData.PK, caseData.SK, user?.signInUserSession?.accessToken?.jwtToken)
            setSection('AudioTranscript', true, 'complete');
            setIsSubmitting(false);


    }
    const [selectedJudge, setSelectedJudge] = useState(lookupJudge('neutral'));

    const [sectionStates, setSectionStates] = useState({
        PickJudge: { open: true, state: 'ready' },
        CaseForm: { open: true, state: 'empty' },
        JudgesThoughts: { open: false, state: 'empty' },
        CaseSummary: { open: false, state: 'empty' },
        FinalRuling: { open: false, state: 'empty' },
        AudioTranscript: { open: false, state: 'empty' },
        Feedback: { open: false, state: 'ready' },
        Disclaimer: { open: true, state: 'ready' }
    });

    const handleJudgeChange = (e) => {
        const judge = judgeOptions.find(option => option.value === e.target.value);
        setSelectedJudge(judge);
    };

    const handleAddCredits = (e) => {
        navigate('/pricing?appname=courthouse')
    };

    useEffect(() => {
        checkUserAuthentication(setUser);

    }, []);

    const refreshCredits = () => {
        if (user?.signInUserSession?.accessToken?.jwtToken) {
            getProfile(user?.signInUserSession?.accessToken?.jwtToken).then(resp => {
                setCredits(resp?.credits);
            });
        }
    }
    useEffect(() => {
        if (setup_intent !== null && setup_intent !== undefined) {
            // Set a delay (e.g., 2000 milliseconds = 2 seconds)
            setTimeout(refreshCredits, 500);
        };
        refreshCredits()
    }, [user]);

    const populateExample = () => {
        const cases = getSampleCase();


        setSectionStates({
            PickJudge: { open: true, state: 'complete' },
            CaseForm: { open: true, state: 'complete' },
            JudgesThoughts: { open: true, state: 'complete' },
            CaseSummary: { open: true, state: 'complete' },
            FinalRuling: { open: true, state: 'complete' },
            AudioTranscript: { open: true, state: 'complete' },
            Feedback: { open: true, state: 'ready' },
            Disclaimer: { open: false, state: 'ready' }

        })
        setDispute(cases.dispute);
        setPartyA(cases.partyA);
        setPartyB(cases.partyB);
        setFormComplete(true);
        setCaseValidation(cases.validation);
        setCaseFactsIssueRules(cases.factsIssueRules);
        setCaseRuling(cases.ruling);
        setSelectedJudge(lookupJudge(cases.judge));
        setExampleButton(`See Example ${cases.next_example_no}`);
        setPresignedUrl(cases.presignedUrl)
    }

    const LoadNewExample = (tabNumber) => {
        setActiveTab(tabNumber);
        if (tabNumber === 0) {
            navigate(`${location.pathname}?`, { replace: true });
            initializeCourthouse('');
            // window.location.reload();
        }
        else {
            // setSectionTitle('Example 1')
            // navigate(`${location.pathname}?exampleNo=${Number(tabNumber) - 1}`, { replace: true });
            populateExample(tabNumber - 1);

        };

    }
    const initializeCourthouse = (defaultJudge) => {
        refreshCredits()
        setSectionStates({
            PickJudge: { open: true, state: 'complete' },
            CaseForm: { open: true, state: 'empty' },
            JudgesThoughts: { open: false, state: 'empty' },
            CaseSummary: { open: false, state: 'empty' },
            FinalRuling: { open: false, state: 'empty' },
            AudioTranscript: { open: false, state: 'empty' },
            Feedback: { open: false, state: 'ready' },
            Disclaimer: { open: true, state: 'ready' }
        })
        setSelectedJudge(lookupJudge('neutral'));
        setDispute('');
        setPartyA('');
        setPartyB('');
        setCaseValidation(defaultJudge);
        setCaseFactsIssueRules(defaultJudge);
        setCaseRuling(defaultJudge);
        setIsSubmitting(false);
        setFormComplete(false);
        setPresignedUrl('');
    }

    const handleNewDispute = () => {
        window.location.reload();

    }


    const handleModalClose = (buttonClicked) => {
        setShowModal(false);
        if (buttonClicked === 'Ok') { navigate('/signin', { state: { pathname: location.pathname, dispute: dispute, partyA: partyA, partyB: partyB } }) }
    };

    const handleCreditsModalClose = (buttonClicked) => {
        setShowCreditsModal(false);
        if (buttonClicked === 'Ok') { navigate('/pricing?appname=courthouse', { state: { pathname: location.pathname, dispute: dispute, partyA: partyA, partyB: partyB } }) }
    };



    const handleSubmit = async (event) => {
        // initializeCourthouse('...');
        event.preventDefault();
        setIsSubmitting(true);

        if (user) {
            setSection('CaseForm', false, 'complete');
            setFormComplete(true);
            setSection('JudgesThoughts', true, 'inprogress');
            const response = await submitArgument(partyA, partyB, dispute, selectedJudge.internal, user.signInUserSession.accessToken.jwtToken);
            pollGetCase(response);


        } else {
            setShowModal(true);
            setIsSubmitting(false);
        }

    };

    const userCheck = (setInputFunction, value) => {

        if (user) {
            if (credits > 0) {
                setInputFunction(value);
                 // } else if (user?.signInUserSession?.accessToken?.jwtToken && credits===null) {
            //     console.log('refresh')
            //     refreshCredits();
            //     userCheck(setInputFunction, value);
            } else {
                setShowCreditsModal(true);
            }

        }
        else {
            setShowModal(true);
        }


    }
    const pollGetCase = async (record) => {
        let complete = false;
        let counter = 1
        while (!complete) {
            complete = await fetchCase(record, counter);
            await new Promise(resolve => setTimeout(resolve, 5000));
            counter = counter + 1
            if (counter > 25) {
                complete = true;
            }
        }
    };
    let errorCount = 1;

    const fetchCase = async (record, counter) => {

        if (errorCount >= 3) {
            console.error('get Case error above threshold...', errorCount);
            setIsSubmitting(false);
            return true;
        }
        try {

            const caseResponse = await getCase(record.PK, record.SK, user.signInUserSession.accessToken.jwtToken, counter);
            setCaseData(caseResponse)
            if (caseResponse.validation) {
                setCaseValidation(caseResponse.validation);
                setSection('JudgesThoughts', true, 'complete');
                setSection('CaseSummary', true, 'inprogress');
            };
            if (caseResponse.facts_issue_rules) {
                setCaseFactsIssueRules(caseResponse.facts_issue_rules);
                setSection('CaseSummary', true, 'complete');
                setSection('FinalRuling', true, 'inprogress');
            };
            if (caseResponse.ruling) {
                setCaseRuling(caseResponse.ruling);
                setSection('FinalRuling', true, 'complete');
                setSection('AudioTranscript', true, 'inprogress');
            };

            if (caseResponse.audio_ruling) {
                setSection('AudioTranscript', true, 'complete');
                setSection('Feedback', true, 'ready');
                setPresignedUrl(caseResponse.audio_ruling)
                setIsSubmitting(false);
                refreshCredits();
                return true;
            };

        } catch (error) {
            console.error(error);
            errorCount = errorCount + 1;
            return false;

        }
        return false
    };



    const toggleSection = (sectionObject) => {

        setSectionStates(prevState => {
            const sectionKey = Object.keys(prevState).find(key => prevState[key] === sectionObject);

            if (!sectionKey) {
                console.error("Section not found");
                return prevState;
            }

            return {
                ...prevState,
                [sectionKey]: {
                    ...sectionObject,
                    open: !sectionObject.open
                }
            };
        });
    };

    const setSection = (section, setOpen, setState) => {
        setSectionStates(prevState => ({
            ...prevState,
            [section]: { open: setOpen, state: setState }
        }));
    };

    const ProgressBar = ({ state }) => {
        return (
            <div className={`courthouse-progress-bar-container courthouse-state-${state}`}>
                <div className="courthouse-progress-bar"></div>
                <div className="courthouse-progress-check"></div>
            </div>
        );
    };


    const AccordionSection = ({ sectionName, content, sectionState, toggleSection }) => {
        return (
            <div className={`courthouse-accordion-item ${sectionState?.open ? 'open' : ''} ${sectionState?.state === 'empty' ? 'disabled' : ''}`}>
                <div className="courthouse-accordion-header" onClick={() => { if (true) toggleSection(sectionState); }}>
                    <div className="courthouse-accordion-header-title">{sectionName}</div>
                    <ProgressBar state={sectionState?.state} />
                    <span className="courthouse-chevron">{sectionState?.open ? '-' : '+'}</span>
                </div>
                <div className="courthouse-accordion-content">
                    <div>
                        {content}
                    </div>
                </div>
            </div>
        );
    };

    const handleFeedbackProgress = async (state) => {
        setSection('Feedback', true, state);
    }

    return (
        <div className="courthouse-container">
            <Modal
                isOpen={showModal}
                onClose={handleModalClose}
                message="You must first log in before the AI judge will sit in judgment."
                buttons={['Ok', 'No Thanks']}
            />
            <Modal
                isOpen={showCreditsModal}
                onClose={handleCreditsModalClose}
                message="You are out of case credits. Do you wish to purchase some?"
                buttons={['Ok', 'No Thanks']}
            />
            <div className="courthouse-heading">&#9878; AI Courthouse &#9878;</div>
            <div className="courthouse-subtitle"> Plead your case before the honorable AI judge... </div>

            <nav className="courthouse-nav">
                <ul className="nav-list">
                    <li className={activeTab === 0 ? 'nav-item active' : 'nav-item'} onClick={() => LoadNewExample(0)}>Try it</li>
                    <li className={activeTab === 1 ? 'nav-item active' : 'nav-item'} onClick={() => LoadNewExample(1)}>{exampleButton}</li>
                </ul>
            </nav>
            <div className="courthouse-disclaimer" > {disclaimer} </div>

            <div className="courthouse-accordion">
                <div className={`courthouse-accordion-item ${sectionStates.CaseForm.open ? 'open' : ''}`}>
                    <div className="courthouse-accordion-header" onClick={() => { if (true) toggleSection(sectionStates.CaseForm); }}>
                        <div className="courthouse-accordion-header-title">{"Submit Dispute"}</div>
                        <ProgressBar state={sectionStates.CaseForm.state} />
                        <span className="courthouse-chevron">{sectionStates.CaseForm.open ? '-' : '+'}</span>
                    </div>
                    <div className="courthouse-accordion-content">

                        <div>
                            {
                                <form onSubmit={handleSubmit}>
                                    <div className="courthouse-input-group">
                                        <label className="courthouse-input-label" htmlFor="dispute">Nature of the Dispute:</label>
                                        <textarea
                                            id="dispute"
                                            className="courthouse-form-textarea-input"
                                            placeholder="Provide a brief and neutral description of the dispute without assigning blame or taking sides."
                                            value={dispute}
                                            onChange={(e) => userCheck(setDispute, e.target.value)}
                                            required
                                            disabled={formComplete}
                                        />
                                    </div>
                                    <div className="courthouse-input-group">
                                        <label className="courthouse-input-label" htmlFor="partyA">Party A's Perspective:</label>
                                        <textarea
                                            id="partyA"
                                            className="courthouse-form-textarea-input"
                                            placeholder="Party A, please detail your perspective on the dispute."
                                            value={partyA}
                                            onChange={(e) => userCheck(setPartyA, e.target.value)}
                                            required
                                            disabled={formComplete}
                                        />
                                    </div>
                                    <div className="courthouse-input-group">
                                        <label className="courthouse-input-label" htmlFor="partyB">Party B's Perspective:</label>
                                        <textarea
                                            id="partyB"
                                            className="courthouse-form-textarea-input"
                                            placeholder="Party B, please detail your perspective on the dispute."
                                            value={partyB}
                                            onChange={(e) => userCheck(setPartyB, e.target.value)}
                                            required
                                            disabled={formComplete}
                                        />
                                    </div>
                                    <label className="courthouse-input-label" htmlFor="partyB">Pick your AI judge's Personality:</label>
                                    <div className="courthouse-dropdown-container">


                                        <select className="courthouse-dropdown-select" value={selectedJudge.value} onChange={handleJudgeChange} disabled={formComplete}>
                                            {judgeOptions.map(option => (
                                                <option key={option.value} value={option.value}>{option.value}</option>
                                            ))}
                                        </select>
                                        <div className="courthouse-description-text">
                                            {selectedJudge.description}
                                        </div>
                                    </div>
                                    {user && (<div className="courthouse-input-group">
                                        <label className="courthouse-input-label" >Remaining Case Credits:</label>
                                        <div className="courthouse-button-group">

                                            <div className="credits-display">{credits ? (credits) : ('...')}</div>
                                            <button className="courthouse-submit-button" onClick={handleAddCredits} disabled={isSubmitting}>
                                                Add Credits
                                            </button >

                                        </div>
                                    </div>)}
                                    <div className="courthouse-button-group">
                                        {(!formComplete) && (<button className="courthouse-submit-button" type="submit" disabled={formComplete}>
                                            {isSubmitting ? 'Submitting...' : 'Submit Case'}
                                        </button >
                                        )}

                                        {/* TO FIX prevent users from entering info without credits OR add credits and return to inputs */}

                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>

                <AccordionSection
                    sectionName="Judge's Thoughts"
                    content={<div>{caseValidation}</div>}
                    sectionState={sectionStates.JudgesThoughts}
                    toggleSection={toggleSection}
                />

                <AccordionSection
                    sectionName="Case Summary"
                    content={<div>{caseFactsIssueRules}</div>}
                    sectionState={sectionStates.CaseSummary}
                    toggleSection={toggleSection}
                />

                <AccordionSection
                    sectionName="Final Ruling"
                    content={<div><div>{caseRuling}</div>
                        {caseRuling && (<div>
                            <div className="courthouse-button-group">
                                <button className="courthouse-button" type="button" disabled={isSubmitting} onClick={handleNewDispute}>
                                    New Dispute
                                </button>
                                {/* <button className="courthouse-button" disabled={isSubmitting} >
                                    Share Publicly?
                                </button> */}
                            </div>

                        </div>
                        )}
                    </div>}
                    sectionState={sectionStates.FinalRuling}
                    toggleSection={toggleSection}
                />

                <AccordionSection
                    sectionName="Audio Ruling"
                    content={<div>
                        {presignedUrl && (<div>
                            <audio controls src={presignedUrl}>
                                Your browser does not support the audio element.
                            </audio>
                            {user && (<div><label className="courthouse-input-label">Email a copy of case to yourself or forward to others:</label>
                            <div className="courthouse-button-group">
                                <button className="courthouse-button" type="button" disabled={isSubmitting} onClick={handleSendAudioTranscript}>
                                    {sectionStates.AudioTranscript.state === 'inprogress' ? "Sending..." : "Email a copy"}
                                </button>
                            </div></div>)}
                            
                        </div>
                        )}
                    </div>}
                    sectionState={sectionStates.AudioTranscript}
                    toggleSection={toggleSection}
                />

                <AccordionSection
                    sectionName="Feedback"
                    content={<div>

                        <Feedback title='Please share your feedback.'
                            subtitle='What did you think of the ruling?'
                            user={user}
                            context="courthouse"
                            complete={sectionStates.Feedback.state === 'complete'}
                            stateHandler={handleFeedbackProgress} />
                    </div>}
                    sectionState={sectionStates.Feedback}
                    toggleSection={toggleSection}
                />

            </div>

        </div >
    );
};

export default Courthouse;